import { DateTime } from "luxon";
import {
  Approximate,
  type ApproximateRange,
  type DateBoundary,
  DateRangeBoundary,
  GenericReference,
  LocalDate,
  NoteSectionForm,
  QuestionnaireAnswer,
  QuestionnaireAnswerSet,
  QuestionnaireAnswerValue,
  QuestionnaireElement,
  RelativeOccassion,
  Resource,
  RichText
} from "@remhealth/apollo";
import { type Labeling, Yup } from "@remhealth/core";
import { DateFormats, parseDate } from "@remhealth/ui";
import { FormNoteSection, ValidationMode } from "~/notes/types";
import { QuestionFlavor, hasLinkedSectionForm, questionFlavorDisplay, resolveQuestionFlavor, unanswerableQuestionFlavors } from "~/questionnaire/flavors";
import { QuestionnaireContext } from "~/questionnaire/contexts";
import { getQuestionDependencies } from "~/questionnaire/dependencies";
import { answerValueHasValue } from "~/questionnaire/utils";

type ElementToAnswerSchemaLkup = Map<string, Yup.ObjectSchema<QuestionnaireAnswer>>; // LinkID -> QuestionnaireAnswer schema
type AnswerSchemaFactory = (answers: QuestionnaireAnswer[]) => Yup.ArraySchema<QuestionnaireAnswer>;

export interface FormNoteSectionSchemaContext {
  forms: NoteSectionForm[];
  questionnaireContext: QuestionnaireContext;
}

export function formNoteSectionSchema(context: FormNoteSectionSchemaContext, validationMode: ValidationMode, labels: Labeling) {
  const formLkup = new Map<string, ElementToAnswerSchemaLkup>();

  const questionnaireContext = context.questionnaireContext;
  const sessionPeriod = questionnaireContext.period;

  // Pre-generate the schema for all question elements so they
  // can be looked up conditionally later when answers are added
  context.forms.forEach(form => generateFormSchema(form, 0));

  return Yup.lazy<FormNoteSection>(inferFormNoteSectionSchema);

  function generateFormSchema(form: NoteSectionForm, depth = 0) {
    const answerLkup = new Map<string, Yup.ObjectSchema<QuestionnaireAnswer>>();
    formLkup.set(form.id, answerLkup);

    form.elements.forEach(question => {
      const enforceRequired = validationMode === "strict"
        || (validationMode === "patient-viewables" && question.includeInPatientView);

      if (hasLinkedSectionForm(question) && question.form.resource) {
        const factory = createAnswerSchemaFactory(question.form.resource);
        const schema = createAnswerSchema(question, enforceRequired, factory, depth, labels, sessionPeriod);

        answerLkup.set(question.linkId, schema);

        // Recursive step
        if (depth === 0 && !formLkup.has(question.form.resource.id)) {
          generateFormSchema(question.form.resource, depth + 1);
        }
      } else {
        const schema = createAnswerSchema(question, enforceRequired, undefined, depth, labels, sessionPeriod);
        answerLkup.set(question.linkId, schema);
      }
    });
  }

  function inferFormNoteSectionSchema(value: FormNoteSection) {
    const factory = createAnswerSchemaFactory(value.noteSectionForm);

    return Yup.object<FormNoteSection>({
      name: Yup.string().required(),
      noteSectionForm: Yup.object<Pick<NoteSectionForm, "elements">>({
        elements: Yup.array(),
      }) as Yup.ObjectSchema<NoteSectionForm>, // TODO
      required: Yup.boolean(),
      questionnaireAnswers: Yup.lazy<QuestionnaireAnswer[]>(factory),
    });
  }

  function createAnswerSchemaFactory(form: NoteSectionForm) {
    return function factory(answers: QuestionnaireAnswer[]): Yup.ArraySchema<QuestionnaireAnswer> {
      const answerLkup = formLkup.get(form.id);
      return !answerLkup ? Yup.array() : Yup.array().of<QuestionnaireAnswer>(
        Yup.lazy<QuestionnaireAnswer>(
          inferQuestionnaireAnswerSchema(answerLkup, form.elements, answers, questionnaireContext)
        )
      );
    };
  }
}

function inferQuestionnaireAnswerSchema(answerSchemas: ElementToAnswerSchemaLkup, elements: QuestionnaireElement[], answers: QuestionnaireAnswer[], context: QuestionnaireContext) {
  const questionDependencies = getQuestionDependencies(elements, context);

  return function schema(questionAnswer: QuestionnaireAnswer): Yup.ObjectSchema<QuestionnaireAnswer> {
    const question = elements.find(e => e.linkId === questionAnswer.linkId);

    if (!question) {
      return Yup.object();
    }

    const isVisible = questionDependencies.isVisible(question, answers);

    if (!isVisible) {
      return Yup.object();
    }

    return answerSchemas.get(questionAnswer.linkId) ?? Yup.object();
  };
}

const phoneFormatTypeRegex = /^\(\d{3}\)\s?\d{3}-\d{4}$/;
const ssnFormatTypeRegex = /^\d{3}-\d{2}-\d{4}$/;
const postalCodeFormatTypeRegex = /^\d{5}(-\d{4})?$/;

function createAnswerSchema(question: QuestionnaireElement, enforceRequired: boolean, factory: AnswerSchemaFactory | undefined, depth = 0, labels: Labeling, period?: ApproximateRange): Yup.ObjectSchema<QuestionnaireAnswer> {
  const flavor = resolveQuestionFlavor(question);

  if (flavor === QuestionFlavor.Ranking || unanswerableQuestionFlavors.has(flavor)) {
    return Yup.object();
  }

  const isRequired = question.required && enforceRequired;
  const isValueTextRequired = isRequired && flavor === QuestionFlavor.MultilineText && (!question.form?.resource?.id || depth > 0);
  const isValueStringRequired = isRequired && flavor === QuestionFlavor.SinglelineText;
  const isValueApproximateRequired = isRequired && flavor === QuestionFlavor.DateTime;
  const isValueDateRequired = isRequired && flavor === QuestionFlavor.Date;
  const isValueTimeRequired = isRequired && flavor === QuestionFlavor.Time;
  const isValueDurationRequired = isRequired && flavor === QuestionFlavor.Duration;
  const isValueReferenceRequired = isRequired && (flavor === QuestionFlavor.StaffSearch || flavor === QuestionFlavor.ServiceSearch);
  const isValueBooleanTrueRequired = isRequired && flavor === QuestionFlavor.Checkbox;
  const isValueBooleanRequired = isRequired && (flavor === QuestionFlavor.Checkbox || flavor === QuestionFlavor.YesNo);
  const isValueIntegerRequired = isRequired && flavor === QuestionFlavor.Integer;
  const isValueDecimalRequired = isRequired && flavor === QuestionFlavor.Decimal;
  const isValueGridRequired = isRequired && (flavor === QuestionFlavor.Grid || (flavor === QuestionFlavor.MultilineText && !!question.form?.resource?.id && depth === 0));
  const isValueCodeRequired = isRequired && flavor === QuestionFlavor.UsStateLookup;

  const minAnswers = question.answerMinimum ?? 1;
  const maxAnswers = question.answerMaximum;
  const lowerBound = question.lowerBound;
  const upperBound = question.upperBound;

  const label = questionFlavorDisplay[flavor];

  let valuesSchema = Yup.array<QuestionnaireAnswerValue>().of(Yup.object<QuestionnaireAnswerValue>({
    valueString: Yup.string()
      .max(question?.maxLength ?? 1000)
      .min(question?.minLength ?? 0)
      .label(label)
      .test("required", `${label} is a required field.`, function(value: string | undefined) {
        return isValueStringRequired ? !!value?.trim() : true;
      })
      .test("invalid phone number", "Invalid phone format", function(value: string | undefined) {
        if (question.presentationHint !== "Phone") {
          return true;
        }
        if (!value || value === "(___) ___-____") {
          return true;
        }
        return phoneFormatTypeRegex.test(value);
      })
      .test("invalid social security number", "Invalid social security number format", function(value: string | undefined) {
        if (question.presentationHint !== "SocialSecurityNumber") {
          return true;
        }
        if (!value || value === "___-__-____") {
          return true;
        }
        return ssnFormatTypeRegex.test(value);
      })
      .test("invalid postal codes", "Invalid postal code format", function(value: string | undefined) {
        if (question.presentationHint !== "PostalCode") {
          return true;
        }
        if (!value) {
          return true;
        }
        return postalCodeFormatTypeRegex.test(value);
      })
      .test("invalid name", "Invalid name format", function(value: string | undefined) {
        if (question.presentationHint !== "LastFirstName") {
          return true;
        }
        if (!value) {
          return true;
        }
        const comma = value.indexOf(",");
        if (comma === -1) {
          return false;
        }
        const lastName = value.slice(0, comma).trim();
        const firstName = value.slice(comma + 1).trim();
        return !!lastName && !!firstName;
      }),
    valueBoolean: Yup.boolean()
      .label(label)
      .test("required-boolean", "Choice is required.", function(value: boolean | undefined) {
        return isValueBooleanRequired ? value !== undefined : true;
      })
      .test("required-true", "Must be checked.", function(value: boolean | undefined) {
        return isValueBooleanTrueRequired ? value === true : true;
      }),
    valueInteger: Yup.number().requiredWhen(isValueIntegerRequired).label(label)
      .test("range", `Number must be between ${question.lowerBound} and ${question.upperBound}`, function(value: number) {
        if (value !== undefined) {
          if (lowerBound !== undefined && value < lowerBound) {
            return false;
          }
          if (upperBound !== undefined && value > upperBound) {
            return false;
          }
        }
        return true;
      }),
    valueDecimal: Yup.number().requiredWhen(isValueDecimalRequired).label(label)
      .test("range", `Number must be between ${question.lowerBound} and ${question.upperBound}`, function(value: number) {
        if (value !== undefined) {
          if (lowerBound !== undefined && value < lowerBound) {
            return false;
          }
          if (upperBound !== undefined && value > upperBound) {
            return false;
          }
        }
        return true;
      }),
    valueText: Yup.object<RichText>()
      .label(label)
      .test("min-length", `Answer must be at least ${question?.minLength ?? 0} characters.`, function(value: RichText | undefined) {
        return !value?.plainText || value.plainText.length >= (question?.minLength ?? 0);
      })
      .test("max-length", `Answer must be at most ${question?.maxLength ?? 50000} characters.`, function(value: RichText | undefined) {
        return !value?.plainText || value.plainText.length <= (question?.maxLength ?? 50000);
      })
      .test("required", "Text is a required field", function(value: RichText | undefined) {
        return isValueTextRequired ? !!value?.plainText?.trim() : true;
      }),
    valueApproximate: Yup.mixed()
      .requiredWhen(isValueApproximateRequired)
      .label(label)
      .test("max-date", "Date must be prior to today's date", function(value: Approximate | undefined) {
        if (question.maxDate && value) {
          const boundary = getDateFromBoundary(question.maxDate).endOf("day");
          const selectedDate = Approximate.toDateTime(value);
          return selectedDate < boundary;
        }
        return true;
      })
      .test("min-date", "Date must be after today's date", function(value: Approximate | undefined) {
        if (question.minDate && value) {
          const boundary = getDateFromBoundary(question.minDate);
          const selectedDate = Approximate.toDateTime(value);
          return selectedDate > boundary;
        }
        return true;
      })
      .test("date range", "Date must be within/outside date range", function(value: Approximate | undefined) {
        if (question.dateRange && value && period?.start && period?.end) {
          const start = Approximate.toDateTime(period.start);
          const end = Approximate.toDateTime(period.end);
          const selectedDate = Approximate.toDateTime(value);
          if (question.dateRange === DateRangeBoundary.OutsidePeriod && selectedDate >= start && selectedDate <= end) {
            return this.createError({ message: `Date must not be within the ${labels.session} date/time.` });
          } else if (question.dateRange === DateRangeBoundary.WithinPeriod && !(selectedDate >= start && selectedDate <= end)) {
            return this.createError({ message: `Date must be within the ${labels.session} date/time.` });
          }
        }
        return true;
      }),
    valueDate: Yup.mixed()
      .test("invalid date", "Date is invalid, please try MM/DD/YYYY or MM-DD-YYYY.", function(value: LocalDate | undefined) {
        if (!value) {
          return true;
        }
        const parsedDate = parseDate(DateFormats.date(LocalDate.toDate(value)));
        return !!parsedDate;
      })
      .test("max-date", "Date must be prior to today's date", function(value: LocalDate | undefined) {
        if (question.maxDate && value) {
          const boundary = getDateFromBoundary(question.maxDate).endOf("day");
          const selectedDate = LocalDate.toDateTime(value);
          return selectedDate < boundary;
        }
        return true;
      })
      .test("min-date", "Date must be after today's date", function(value: LocalDate | undefined) {
        if (question.minDate && value) {
          const boundary = getDateFromBoundary(question.minDate);
          const selectedDate = LocalDate.toDateTime(value);
          return selectedDate > boundary;
        }
        return true;
      })
      .test("date range", "Date must be within date range", function(value: LocalDate | undefined) {
        if (question.dateRange && value && period?.start && period?.end) {
          const start = Approximate.toDateTime(period.start);
          const end = Approximate.toDateTime(period.end);
          const selectedDate = LocalDate.toDateTime(value);
          if (question.dateRange === DateRangeBoundary.OutsidePeriod && (selectedDate >= start && selectedDate <= end)) {
            return this.createError({ message: `Date must not be outside the ${labels.session} date/time` });
          } else if (question.dateRange === DateRangeBoundary.WithinPeriod && !(selectedDate >= start && selectedDate <= end)) {
            return this.createError({ message: `Date must be within the ${labels.session} date/time.` });
          }
        }
        return true;
      })
      // .test("is-valid", getErrorMessage(question), testDateBoundary)
      .requiredWhen(isValueDateRequired)
      .label(label),
    valueTime: Yup.mixed()
      .requiredWhen(isValueTimeRequired)
      .label(label),
    valueDuration: Yup.mixed()
      .requiredWhen(isValueDurationRequired)
      .label(label),
    valueReference: Yup.mixed()
      .test("empty reference", `${label} is a required field`, function(value: GenericReference<Resource> | undefined) {
        if (!value || (flavor !== QuestionFlavor.StaffSearch && flavor !== QuestionFlavor.ServiceSearch)) {
          return true;
        }

        return !!value.id && !!value.display;
      })
      .requiredWhen(isValueReferenceRequired)
      .label(label),
    valueCode: Yup.mixed()
      .requiredWhen(isValueCodeRequired)
      .label(label),
    valueGrid: (!factory
      ? Yup.array()
        .of<QuestionnaireAnswerSet>(Yup.object())
      : Yup.array()
        .of<QuestionnaireAnswerSet>(Yup.object<QuestionnaireAnswerSet>({
          answers: Yup.lazy<QuestionnaireAnswer[]>(factory),
        })))
      .requiredWhen(isValueGridRequired, "Must add at least one entry.")
      .min(isValueGridRequired ? 1 : 0, "Must add at least one entry.")
      .label(label),
  }))
    .label(label)
    .test("minimum", createErrorMessage([]), testMinimum)
    .test("ehrLookup", createErrorMessage([]), testEhrLookup);

  if (isRequired) {
    valuesSchema = valuesSchema.required();
  }

  // Always validate answer maximum
  if ((flavor === QuestionFlavor.MultiChoice || flavor === QuestionFlavor.MultiDecision) && maxAnswers !== undefined) {
    valuesSchema = valuesSchema.max(maxAnswers, params => createErrorMessage(params.value));
  }

  return Yup.object<QuestionnaireAnswer>({
    linkId: Yup.string(),
    values: valuesSchema,
  });

  function testMinimum(value: QuestionnaireAnswerValue[] | undefined): boolean {
    // When signing
    if (enforceRequired) {
      // Must have a value
      if (question.required) {
        return !!value && value.length >= minAnswers;
      }

      // Value is optional, but if provided must meet minimum
      if (!value || value.length === 0) {
        return true;
      }

      return value.length >= minAnswers;
    }

    return true;
  }

  function testEhrLookup(value: QuestionnaireAnswerValue[] | undefined): boolean {
    if (isRequired && flavor === QuestionFlavor.EhrLookup) {
      if (!value) {
        return false;
      }

      return value.some(v => v.valueReference || v.valueCode);
    }

    return true;
  }

  function createErrorMessage(values: QuestionnaireAnswerValue[] | undefined) {
    const isAnswerEmpty = !values?.some(answerValueHasValue);

    if (isAnswerEmpty) {
      return `${label} is required`;
    }

    if (minAnswers === maxAnswers) {
      return `${label} field requires ${minAnswers} selections`;
    }

    return `${label} field requires ${minAnswers} to ${maxAnswers} selections`;
  }
}

function getDateFromBoundary(boundary: DateBoundary): DateTime {
  switch (boundary.relativeOccassion) {
    case RelativeOccassion.CurrentDay: return DateTime.now();
    case RelativeOccassion.StartOfMonth: return DateTime.now().startOf("month");
    case RelativeOccassion.StartOfWeek: return DateTime.now().startOf("week");
    case RelativeOccassion.StartOfYear: return DateTime.now().startOf("year");
    case RelativeOccassion.EndOfMonth: return DateTime.now().endOf("month");
    case RelativeOccassion.EndOfWeek: return DateTime.now().endOf("week");
    case RelativeOccassion.EndOfYear: return DateTime.now().endOf("year");
  }
}
