import * as Core from "@blueprintjs/core";
import { Icon, IconName, IconProps } from "./icon";

export interface CalloutProps extends Omit<Core.CalloutProps, "icon"> {
  icon?: IconName | React.ReactElement<IconProps> | null;
}

export const Callout = (props: CalloutProps) => {
  const { icon, ...restProps } = props;

  const nativeIcon: Core.MaybeElement | null | undefined = icon === null
    ? null
    : icon
      ? <Icon aria-hidden icon={icon} />
      : undefined;

  return (
    <Core.Callout icon={nativeIcon} {...restProps} />
  );
};
