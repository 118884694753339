import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Note, isPatientNote } from "@remhealth/apollo";
import { Overlay } from "@remhealth/ui";
import { NoteSummaryPreviewProps } from "./noteSummaryPreview";
import { Container, StyledNotePrint } from "./noteSummaryPrint.styles";

export interface NoteSummaryPrintProps {
  isOpen: boolean;
  note: Note;
  onAfterPrint?: () => void;
  isPatientView?: boolean;
}

export function NoteSummaryPrint(props: NoteSummaryPrintProps) {
  const { isOpen, note, isPatientView, onAfterPrint } = props;

  const preview = useRef<NoteSummaryPreviewWrap>(null);
  const handlePrint = useReactToPrint({
    content: () => preview.current,
    onAfterPrint,
    bodyClass: "bells-app",
    pageStyle: "@page { size: auto; margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
  });

  if (!isOpen || !note || !isPatientNote(note)) {
    return <></>;
  }

  return (
    <Overlay isOpen hasBackdrop={false}>
      <Container>
        <NoteSummaryPreviewWrap ref={preview} isPatientView={isPatientView} note={note} onReady={handlePrint} />
      </Container>
    </Overlay>
  );
}

class NoteSummaryPreviewWrap extends React.PureComponent<NoteSummaryPreviewProps> {
  public render() {
    return <StyledNotePrint isPrintView {...this.props} />;
  }
}
