import {
  AppAuthError,
  AuthorizationError,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_REFRESH_TOKEN,
  TokenRequest
} from "@openid/appauth";
import type { AccessTokenClaims, AccessTokenIdentity } from "./accessToken";
import { decodeJwt } from "./jwt";
import { FetchError, FetchRequestor } from "./requester";
import type { OAuthConfiguration } from "./configuration";
import {
  type AuthorizationCode,
  type AuthorizationState,
  LoginManager,
  type LoginRequest,
  type LoginRequestHandler,
  LoginResponseManager,
  LogoutManager,
  type LogoutRequest,
  type LogoutRequestHandler,
  type ResponseTypeCodeConfiguration
} from "./login";

export interface AuthenticationCompletion {
  token: AccessTokenIdentity;
  state?: AuthorizationState;
}

export class Authenticator {
  private readonly configuration: OAuthConfiguration;
  private metadata: AuthorizationServiceConfiguration | null = null;

  constructor(configuration: OAuthConfiguration) {
    this.configuration = configuration;
  }

  /*
   * Do the authorization redirect.
   */
  public async login(request: LoginRequest, handler: LoginRequestHandler, abort: AbortSignal): Promise<AuthenticationCompletion> {
    try {
      if (request.forceAuth) {
        abort = new AbortController().signal;
      }

      // Download metadata from the Authorization server if required
      const metadata = await this.loadMetadata(abort);

      // Do the work of the login
      const config: ResponseTypeCodeConfiguration = {
        ...this.configuration,
        scope: request.scope,
      };
      const loginManager = new LoginManager(config, metadata, handler);
      const code = await loginManager.login(request);
      return this.swapAuthorizationCodeForTokens(code, request.redirectUri, abort);
    } catch (error) {
      throw new AppAuthError("A technical problem occurred during login processing", { code: "loginRequestFailed", innerError: error });
    }
  }

  /*
   * Implement logout by redirecting to remove the Authorization Server session cookie
   */
  public async logout(request: LogoutRequest, handler: LogoutRequestHandler, abort: AbortSignal): Promise<void> {
    try {
      // Download metadata from the Authorization server if required
      const metadata = await this.loadMetadata(abort);

      // Start the logout redirect to remove the authorization server's session cookie
      const logout = new LogoutManager(this.configuration, metadata, handler);
      await logout.start(request);
    } catch (error) {
      throw new AppAuthError("A technical problem occurred during logout processing", { code: "logoutRequestFailed", innerError: error });
    }
  }

  /*
   * Used when user is redirected back to the app.
   */
  public async completeAuthorizationRequest(redirectUri: string, handler: LoginRequestHandler, abort: AbortSignal): Promise<AuthenticationCompletion | null> {
    try {
      // Do the work of the login
      const loginManager = new LoginResponseManager(handler);
      const code = await loginManager.completeAuthorizationRequest();
      if (!code) {
        return null;
      }
      return this.swapAuthorizationCodeForTokens(code, redirectUri, abort);
    } catch (error) {
      throw new AppAuthError("A technical problem occurred during login processing", { code: "loginRequestFailed", innerError: error });
    }
  }

  public async exchangeMammothToken(mammothToken: string, abort: AbortSignal): Promise<AccessTokenIdentity> {
    const tokenRequest = this.createTokenRequest({
      grantType: "mammoth-kex",
      extras: {
        "kex-token": mammothToken,
      },
    });

    try {
      return await this.tokenRequest(tokenRequest, abort);
    } catch (error) {
      throw new AppAuthError("A technical problem occurred during token processing", { code: "tokenExchangeError", innerError: error });
    }
  }

  public async exchangeUnityToken(unityToken: string, issuer: string, abort: AbortSignal): Promise<AccessTokenIdentity> {
    const tokenRequest = this.createTokenRequest({
      grantType: "unity_token",
      extras: {
        token: unityToken,
        jwtIssuer: issuer,
      },
    });

    try {
      return await this.tokenRequest(tokenRequest, abort);
    } catch (error) {
      throw new AppAuthError("A technical problem occurred during token processing", { code: "tokenExchangeError", innerError: error });
    }
  }

  /*
   * Try to perform a token refresh.
   */
  public async refreshToken(refreshToken: string, abort: AbortSignal): Promise<AccessTokenIdentity | null> {
    const tokenRequest = this.createTokenRequest({
      grantType: GRANT_TYPE_REFRESH_TOKEN,
      refreshToken,
    });

    try {
      return await this.tokenRequest(tokenRequest, abort);
    } catch (error) {
      // Sometimes tokens were revoked, so don't treat this as an error
      if (isInvalidGrantError(error)) {
        return null;
      }

      throw new AppAuthError("A technical problem occurred during token processing", { code: "tokenRenewalError", innerError: error });
    }
  }

  public async getUserInfo(accessToken: string, abort: AbortSignal): Promise<AccessTokenClaims> {
    const metadata = await this.loadMetadata(abort);
    if (!metadata.userInfoEndpoint) {
      throw new AppAuthError("A technical problem occurred during token processing", { code: "apiResponseError" });
    }

    const requester = new FetchRequestor(abort);

    const headers = {
      // The required authorization header
      Authorization: `Bearer ${accessToken}`,
    };

    return await requester.xhr<AccessTokenClaims>({
      url: metadata.userInfoEndpoint,
      method: "GET",
      headers,
    });
  }

  /*
   * Try to perform a token request.
   */
  public async tokenRequest(tokenRequest: TokenRequest, abort: AbortSignal): Promise<AccessTokenIdentity> {
    // Download metadata from the Authorization server if required
    const metadata = await this.loadMetadata(abort);

    // Execute the request to send the refresh token and get new tokens
    const requester = new FetchRequestor(abort);
    const tokenHandler = new BaseTokenRequestHandler(requester);
    const tokenResponse = await tokenHandler.performTokenRequest(metadata, tokenRequest);

    // The idToken MUST be returned
    // https://openid.net/specs/openid-connect-core-1_0.html#TokenResponse
    if (!tokenResponse.idToken) {
      throw new AppAuthError("id_token was not returned from token response", { code: "apiResponseError" });
    }

    const profile = await this.getUserInfo(tokenResponse.accessToken, abort);

    // Set values from the response, which may include a new rolling refresh token
    return {
      accessToken: tokenResponse.accessToken,
      refreshToken: tokenResponse.refreshToken ? tokenResponse.refreshToken : null,
      idToken: tokenResponse.idToken,
      issuedAt: tokenResponse.issuedAt,
      expirationTime: tokenResponse.expiresIn ? Date.now() + tokenResponse.expiresIn : null,
      jwt: decodeJwt(tokenResponse.idToken),
      profile,
    };
  }

  /*
   * Swap the authorizasion code for a refresh token and access token
   */
  private async swapAuthorizationCodeForTokens(code: AuthorizationCode, redirectUri: string, abort: AbortSignal): Promise<AuthenticationCompletion> {
    if (!code?.code) {
      throw new AppAuthError("Authorization code was missing code value.", { code: "apiDataError" });
    }

    const tokenRequest = this.createTokenRequest({
      grantType: GRANT_TYPE_AUTHORIZATION_CODE,
      extras: {
        code_verifier: code.verifier,
      },
    });

    tokenRequest.code = code.code;
    tokenRequest.redirectUri = redirectUri;
    const token = await this.tokenRequest(tokenRequest, abort);
    return { token, state: code.state };
  }

  private async loadMetadata(abort: AbortSignal): Promise<AuthorizationServiceConfiguration> {
    if (!this.metadata) {
      this.metadata = await AuthorizationServiceConfiguration.fetchFromIssuer(
        this.configuration.authority.replace(/\/$/, ""),
        new FetchRequestor(abort)
      );
    }

    return this.metadata;
  }

  private createTokenRequest(data: TokenRequestInit): TokenRequest {
    return new TokenRequest({
      grant_type: data.grantType,
      code: data.code,
      refresh_token: data.refreshToken,
      client_id: this.configuration.clientId,
      redirect_uri: "",
      extras: {
        ...this.configuration.additionalParameters,
        ...data.extras,
      },
    });
  }
}

interface TokenRequestInit {
  grantType: string;
  code?: string;
  refreshToken?: string;
  redirectUri?: string;
  extras?: Record<string, string>;
}

export function isInvalidGrantError(error: any): boolean {
  if (error instanceof AuthorizationError) {
    return error.error === "invalid_grant";
  }
  if (error instanceof FetchError && error.json && typeof error.json === "object" && "error" in error.json) {
    return error.json.error === "invalid_grant";
  }
  if (error instanceof AppAuthError && error.extras && typeof error.extras === "object" && "innerError" in error.extras) {
    return isInvalidGrantError(error.extras.innerError);
  }
  return false;
}
