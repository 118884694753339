import React, { useEffect } from "react";
import classnames from "classnames";
import { first } from "lodash-es";
import { DateFormats } from "@remhealth/ui";
import {
  Appointment,
  Approximate,
  CompositionStatus,
  Condition,
  Encounter,
  GenericReference,
  Group,
  GroupNote,
  HealthcareService,
  IStores,
  Instant,
  LocalDate,
  Location,
  Media,
  NoteDefinition,
  NoteSection,
  NoteSectionForm,
  NoteSectionFormat,
  Organization,
  ParticipantRole,
  Patient,
  PatientNote,
  Practice,
  Practitioner,
  Program,
  Reference,
  RelatedPerson,
  Signature,
  VisitStatus,
  ZonedDateTime,
  systems
} from "@remhealth/apollo";
import { ApolloClients, PersonalPreferencesData, PracticePreferencesData, getProductFlag } from "@remhealth/host";
import { Labeling, getPatientGender, getUserNameAndCredentials } from "@remhealth/core";
import { Text } from "~/text";
import { expandNoteSectionForms, fetchCoverages, fetchGuardian, getVisitStatus } from "~/utils";
import { getEnrollmentAndProgramValue } from "~/notes/utils";
import { noteSummarySection, renderCarePlanActivities, renderSessionTime, renderTextSection } from "./noteSummarySection";

type PatientSignature = Pick<Signature, "signer" | "role" | "signed"> & {
  signatureImage: string;
};

interface NoteSignature extends Omit<Signature, "signer" | "signatureImage"> {
  signer: Practitioner;
  signatureImage: string | undefined;
}

export interface NoteSummaryProps {
  className?: string;
  practice: Practice;
  note: PatientNote;
  practicePreferences: PracticePreferencesData;
  personalPreferences: PersonalPreferencesData | undefined;
  appointment: Appointment | undefined;
  definition: NoteDefinition;
  encounter: Encounter;
  location: Location | undefined;
  program: Program | undefined;
  patient: Patient;
  serviceType: HealthcareService | undefined;
  owner: Practitioner | undefined;
  guardian: RelatedPerson | undefined;
  insurances: Reference<Organization>[];
  conditions: Condition[];
  signature: NoteSignature | undefined;
  problems: Condition[];
  groupNote?: GroupNote;
  labels: Labeling;
  isPatientView: boolean;
  isPrintView: boolean;
  patientSignatures: PatientSignature[];
  onReady?: () => void;
}

export function NoteSummary(props: NoteSummaryProps): JSX.Element {
  const {
    className,
    appointment,
    practice,
    note,
    location,
    program,
    serviceType,
    definition,
    encounter,
    groupNote,
    patient,
    signature,
    practicePreferences,
    labels,
    isPatientView,
    isPrintView,
    patientSignatures,
    onReady,
  } = props;

  const isDraft = note.status === CompositionStatus.Preliminary;
  const patientId = (patient.identifiers ?? []).find(id => id.system === systems.patientId);
  const age = DateFormats.age(LocalDate.toDate(patient.birthDate));
  const birthDate = DateFormats.date(LocalDate.toDate(patient.birthDate));
  const owner = note.participants.find(p => p.role === ParticipantRole.PrimaryPerformer);
  const visitDate = DateFormats.dateTime(Approximate.toDate(encounter.period.start), practicePreferences?.militaryTimeInPrintouts);
  const endTime = DateFormats.time(Approximate.toDate(encounter.period.end), practicePreferences?.militaryTimeInPrintouts);
  const { display: genderDisplay } = getPatientGender(patient);
  const groupEncounter = groupNote?.encounter.resource;
  const groupSummarySection = groupNote?.sections.find(s => s.format === NoteSectionFormat.Text
    && s.type?.codings.some(c => c.code === getVisitStatus(encounter.status)
      && c.system === systems.visitStatus));
  const groupSessionTimeSection = groupNote?.sections.find(s => s.format === NoteSectionFormat.SessionTime);
  const isShowStatus = getVisitStatus(encounter.status) === VisitStatus.Show;
  const allowZeroDurationNonShowNotes = getProductFlag(practice, "AllowZeroDurationNonShowNotes");
  const appointmentStaff = appointment?.attendees.find(a => a.role === "PrimaryPerformer" && a.subject.resourceType === "Practitioner")?.subject.resource as Practitioner;

  const militaryTime = practicePreferences?.militaryTimeInPrintouts ?? false;
  const isMyAvatar = practice.product === "myAvatar";
  const formName = !isMyAvatar ? definition.display : definition.noteForm?.display;
  const isIndependentNote = practice.product === "myAvatar" && !definition.billable;
  const notePrintoutHeading = !practicePreferences.notePrintoutHeadingOverride ? practicePreferences.notePrintoutHeading : practicePreferences.notePrintoutHeadingOverride;

  useEffect(() => {
    onReady?.();
  });

  return (
    <main className={classnames("note", className)}>
      <header>
        <h1 className="practice">{encounter.serviceProvider.display}</h1>
        {isDraft && <span className="draft">{Text.Draft}</span>}
        <dl className="note-details">
          <dt className="date-created">Date Created</dt>
          <dd>{DateFormats.dateTime(Instant.toDateTime(note.created), militaryTime)}</dd>
          {formName && (
            <>
              <dt className="form-name">Form Name</dt>
              <dd>{formName}</dd>
            </>
          )}
          <dt className="patient-name">{labels.Patient}&apos;s Name</dt>
          <dd>{renderPatientName()}</dd>
          <dt className="patient-dob">{labels.Patient}&apos;s DOB</dt>
          <dd>{birthDate}</dd>
        </dl>
        {notePrintoutHeading && (
          <section className="main-heading center">
            {notePrintoutHeading.split("\n").map((line, index) => <p key={index}>{line}</p>)}
          </section>
        )}
      </header>
      <dl className="info">
        {isPrintView && (
          <>
            <dt className="print-date">{Text.PrintDate}</dt>
            <dd>{DateFormats.dateTime(new Date(), militaryTime)}</dd>
          </>
        )}

        <dt className="patient">{labels.Patient}</dt>
        <dd className="list">
          <dl className="patient-info">
            <dt className="name">Name</dt>
            <dd>{patient.display}</dd>
            {patientId && (
              <>
                <dt className="patient-id">MRN</dt>
                <dd>{patientId?.value}</dd>
              </>
            )}
            <dt className="age">Age</dt>
            <dd>{age}</dd>
            <dt className="gender">Gender</dt>
            <dd>{genderDisplay}</dd>
            <dt className="dob">DOB</dt>
            <dd>{birthDate}</dd>
          </dl>
        </dd>

        {practitionerRenderer()}
        {renderLocation()}
        {renderServiceType()}
        {renderEnrollmentProgram()}

        {note.category?.text && (
          <>
            <dt className="note-category">{labels.NoteCategory}</dt>
            <dd>{note.category.text}</dd>
          </>
        )}
        {isMyAvatar && (
          <>
            <dt className="note-status">{Text.Draft}/{Text.Final}</dt>
            <dd>{definition.syncAsDraft ? Text.Draft : Text.Final}</dd>
          </>
        )}
        {isMyAvatar && (
          <>
            <dt className="note-for">Progress Note For</dt>
            <dd>{appointment ? "Existing Appointment/Service" : "New Service"}</dd>
          </>
        )}

        {isShowStatus && !isIndependentNote && (
          <>
            <dt className="visit-date">{Text.SessionDateOrTime(labels)}</dt>
            <dd>
              <span className="date">{visitDate}</span>
              {endTime && <> - <span className="time">{endTime}</span></>}
            </dd>
          </>
        )}
        {isMyAvatar && groupEncounter && (
          <>
            <dt className="group-name">Group Name or Number (For Groups Only)</dt>
            <dd>{groupNote.subject.display} ({renderGroupId(groupNote.subject)})</dd>
          </>
        )}
      </dl>

      {appointment && (
        <dl className="appointment">
          <dt className="appointment">{Text.NoteAppointmentLabel}</dt>
          <dd className="list">
            <dl className="appointment-info">
              {appointment.location.display && (
                <>
                  <dt>{labels.Location}</dt>
                  <dd>{appointment.location.display}</dd>
                </>
              )}
              {appointment.start && (
                <>
                  <dt>{isMyAvatar ? "Session Date" : Text.Date}</dt>
                  <dd>{DateFormats.date(ZonedDateTime.toDateTime(appointment.start))}</dd>
                </>
              )}
              {appointment.start && (
                <>
                  <dt>{isMyAvatar ? "Session Start Time" : "Start Time"}</dt>
                  <dd>{DateFormats.time(ZonedDateTime.toDateTime(appointment.start))}</dd>
                </>
              )}
              {appointment.end && (
                <>
                  <dt>{isMyAvatar ? "Session End Time" : "End Time"}</dt>
                  <dd>{DateFormats.time(ZonedDateTime.toDateTime(appointment.end))}</dd>
                </>
              )}
              {appointmentStaff && (
                <>
                  <dt>{labels.Practitioner}</dt>
                  <dd>{getUserNameAndCredentials(practice, appointmentStaff)}</dd>
                </>
              )}
            </dl>
          </dd>
        </dl>
      )}

      {note.derivedFrom?.resource?.partOf && renderCarePlanActivities(note.derivedFrom.resource?.carePlanActivities ?? [])}
      <h2 className="note-type">{formName}</h2>
      {
        groupNote
          ? renderGroupNoteSections()
          : note.sections.map(section => (
            <React.Fragment key={section.name}>{renderSection(section)}</React.Fragment>
          ))
      }
      {note.patientSignatures.length !== 0 && renderPatientSignatures(militaryTime)}
      {note.reviewers.length !== 0 && renderReviewers()}
      {renderSignature(militaryTime)}
    </main>
  );

  function practitionerRenderer() {
    const secondaryPerformer = note.participants.find(p => p.role === ParticipantRole.SecondaryPerformer)?.individual;
    const title = secondaryPerformer
      ? Text.Practitioners
      : isMyAvatar ? "Practitioner" : Text.NoteOwner;
    const data = secondaryPerformer
      ? (
        <ul className="authors">
          <li>{owner?.individual.display}</li>
          <li>{secondaryPerformer.display}</li>
        </ul>
      ) : owner?.individual.display;

    return (
      <>
        <dt className="owner" data-empty={!owner}>{title}</dt>
        <dd>{data}</dd>
      </>
    );
  }

  function renderLocation() {
    if (!location) {
      return null;
    }

    let locationDisplay = location.display;
    if (location.aliases.length) {
      const alias = location.aliases[0];
      locationDisplay += ` (${alias})`;
    }

    return (
      <>
        <dt className="location">{labels.Location}</dt>
        <dd>{locationDisplay}</dd>
      </>
    );
  }

  function renderServiceType() {
    if (!serviceType) {
      return null;
    }

    const label = !isMyAvatar ? labels.ServiceType : "Service Charge Code";
    let serviceTypeDisplay = serviceType.display;

    if (serviceType.aliases.length) {
      const alias = serviceType.aliases[0];
      serviceTypeDisplay += ` (${alias})`;
    }

    return (
      <>
        <dt className="service-type">{label}</dt>
        <dd>{serviceTypeDisplay}</dd>
      </>
    );
  }

  function renderEnrollmentProgram() {
    if (encounter.episodeOfCare) {
      const label = !isMyAvatar ? Text.EnrollmentProgramLabel(labels) : "Service Program";
      const value = !isMyAvatar ? getEnrollmentAndProgramValue(encounter) : getProgramDisplay(program);
      return (
        <>
          <dt className="enrollment">{label}</dt>
          <dd>{value}</dd>
        </>
      );
    }
    return null;
  }

  function getProgramDisplay(program?: Program) {
    if (program?.display) {
      let programDisplay = program.display;

      if (program.aliases.length) {
        const alias = program.aliases[0];
        programDisplay += ` (${alias})`;
      }
      return programDisplay;
    }
    return null;
  }

  function getSectionClassName(section: NoteSection) {
    switch (section.format) {
      case NoteSectionFormat.Diagnosis: return "diagnoses-section";
      case NoteSectionFormat.GoalsObjectives: return "goal-section";
      case NoteSectionFormat.SessionTime: return "session-time-section";
      case NoteSectionFormat.Text: return "text-section";
      case NoteSectionFormat.Form: return "form-section";
      case NoteSectionFormat.AddOn: return "add-on";
      default: return undefined;
    }
  }

  function renderGroupId(groupReference: GenericReference<Group>) {
    if (groupReference.resource?.aliases[0]?.trim()) {
      return groupReference.resource?.aliases[0]?.trim();
    }

    return "";
  }

  function renderGroupNoteSections() {
    const sections = !note.overrideSessionInformation
      ? note.sections.filter(s => s.format !== NoteSectionFormat.SessionTime)
      : note.sections;
    const isEmptySessionTime = (!isShowStatus && allowZeroDurationNonShowNotes) || !groupEncounter?.period?.start || !groupEncounter.period?.end;

    const sessionTimeSection = note.sections.find(s => s.format === NoteSectionFormat.SessionTime);
    const showSessionTime = !isEmptySessionTime && (!isPatientView || sessionTimeSection?.includeInPatientView);

    return (
      <>
        {groupNote && groupEncounter && (
          <>
            {showSessionTime && (
              <section key="group-visit-time" className="session-time-section">
                <h3>{groupSessionTimeSection?.name}</h3>
                {renderSessionTime(groupNote, groupEncounter, militaryTime, practice)}
              </section>
            )}
            {!isPatientView && !!groupSummarySection?.text?.value && (
              <section key="group-summary" className="text-section">
                <h3>{groupSummarySection?.name}</h3>
                {renderTextSection(groupSummarySection)}
              </section>
            )}
            {!isPatientView && encounter.topics.length > 0 && (
              <section key="group-session" className="text-section">
                <h3>Group Sessions</h3>
                <ul>
                  {encounter.topics.map((t, index) => (<li key={`session-${index}`}>{t.name}-{t.description}</li>))}
                </ul>
              </section>
            )}
          </>
        )}
        {sections.map(section => (
          <React.Fragment key={section.name}>{renderSection(section)}</React.Fragment>
        ))}
      </>
    );
  }

  function renderSection(section: NoteSection) {
    if (section.format !== NoteSectionFormat.Form && !section.includeInPatientView && isPatientView) {
      return null;
    }

    const content = noteSummarySection({ ...props, section });

    if (!content) {
      return null;
    }

    let name = section.name;
    // Use name from note section if the section is a Ctone custom dropdown section
    if (section.format === "CtoneCustomDropdown") {
      const targetNoteSection = note.sections.find(section => section.format === "CtoneCustomDropdown");
      if (targetNoteSection) {
        name = targetNoteSection?.name;
      }
    }

    return (
      <section key={name} className={getSectionClassName(section)}>
        {section.includeSectionHeader && <h3>{name}</h3>}
        {content}
      </section>
    );
  }

  function renderReviewers() {
    return (
      <section key="reviewers" className="reviewers">
        <h3>Requested Approvers</h3>
        <table>
          <tbody>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Final Approval</th>
            </tr>
            {note.reviewers.map(r => (
              <tr key={r.assignee.id}>
                <td>{r.assignee.display}</td>
                <td>{r.supervisor ? "Supervisor" : "Approver"}</td>
                <td>{r.isFinalApprover && <input defaultChecked type="checkbox" />}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    );
  }

  function renderSignature(militaryTime: boolean) {
    if (!signature) {
      return null;
    }

    const signerName = getUserNameAndCredentials(practice, signature.signer);
    const signedDate = isMyAvatar
      ? DateFormats.dateTime(Instant.toDate(signature.signed), militaryTime)
      : `${DateFormats.date(Instant.toDate(signature.signed))} at ${DateFormats.time(Instant.toDate(signature.signed), false)}`;

    return (
      <section className="signature-section">
        <dl>
          {signature.signatureImage
            ? (
              <>
                <dt className="date">{Text.SignatureDate}:</dt>
                <dd>{signedDate}</dd>
                <dt className="signature">{Text.ClinicalSignature}:</dt>
                <dd><img className="signature-image" src={signature.signatureImage} /></dd>
                <dt className="signer-name">{signerName}</dt>
              </>
            )
            : (
              <dd>Electronically Signed by: {signerName} on {signedDate}</dd>
            )}
        </dl>
      </section>
    );
  }

  function renderPatientSignatures(militaryTime: boolean) {
    return patientSignatures.map(signature => {
      return (
        <section key={signature.signed} className="signature-section">
          <dl>
            <dt className="date">{Text.SignatureDate}:</dt>
            <dd>{DateFormats.dateTime(Instant.toDate(signature.signed), militaryTime)}</dd>
            {signature.signatureImage && <dd><img className="signature-image" src={signature.signatureImage} /></dd>}
            <dt className="signer-name">{signature.signer?.display}:</dt>
          </dl>
        </section>
      );
    });
  }

  function renderPatientName() {
    if (!isMyAvatar) {
      return patient.display;
    }

    const nameParts = [patient.name.family, ...patient.name.given.filter(p => !!p)];
    return patientId?.value ? `${nameParts.join(", ")} (${patientId.value})` : nameParts.join(", ");
  }
}

type MediaDownloader = (media: Reference<Media>) => Promise<string>;

export interface LoadNoteSummaryOptions {
  practice: Practice;
  practicePreferences: PracticePreferencesData;
  personalPreferences: PersonalPreferencesData | undefined;
  allowImages: false | MediaDownloader;
  labels: Labeling;
  isPrintView: boolean;
  isPatientView: boolean;
}

export async function loadNoteSummary(
  store: IStores<ApolloClients>,
  note: PatientNote,
  options: LoadNoteSummaryOptions,
  abort: AbortSignal
): Promise<NoteSummaryProps> {
  if (note.meta?.hasTransformations) {
    note = (await store.notes.fetch(note.partition, note.id, { abort })) as PatientNote;
  }

  await expandForms(note.sections.flatMap(s => s.form ?? []));

  const signature = first(note.signatures);

  const [patient, guardian, insurances, encounter, groupNote, owner, signer, definition] = await Promise.all([
    expandPatient(),
    expandGuardian(),
    expandInsurances(),
    expandEncounter(),
    expandGroupNote(),
    expandOwner(),
    expandSigner(signature),
    expandNoteDefinition(),
  ]);

  const [appointment, conditions, problems, program, location, serviceType] = await Promise.all([
    expandAppointment(encounter),
    expandConditions(encounter),
    expandProblems(encounter),
    expandProgram(encounter),
    expandLocation(encounter),
    expandServiceType(encounter),
  ]);

  const allowImages = options.allowImages;

  const signatureImage: string | undefined = signature?.signatureImage && allowImages
    ? await allowImages(signature.signatureImage)
    : undefined;

  let patientSignatures: PatientSignature[] = [];
  if (note.patientSignatures.length > 0 && allowImages) {
    patientSignatures = await Promise.all(note.patientSignatures.map(async signature => {
      const signatureImage = await allowImages(signature.signatureImage!);
      return { ...signature, signatureImage };
    }));
  }

  return {
    appointment,
    definition,
    problems,
    conditions,
    encounter,
    insurances,
    location,
    note,
    patient,
    program,
    guardian,
    owner,
    groupNote,
    serviceType,
    signature: signature && signer ? {
      ...signature,
      signer,
      signatureImage,
    } : undefined,
    practice: options.practice,
    practicePreferences: options.practicePreferences,
    personalPreferences: options.personalPreferences,
    labels: options.labels,
    isPrintView: options.isPrintView,
    isPatientView: options.isPatientView,
    patientSignatures,
  };

  async function expandProblems(encounter: Encounter) {
    return await store.conditions.expand(encounter.problems.map(p => p.problem), { abort });
  }

  async function expandPatient() {
    return await store.patients.expand(note.subject, { abort });
  }

  async function expandOwner() {
    const owner = note.participants.find(participant => participant.role === ParticipantRole.PrimaryPerformer)?.individual;
    return owner ? await store.practitioners.expand(owner, { abort }) : undefined;
  }

  async function expandSigner(signature: Signature | undefined) {
    if (signature?.signer) {
      return await store.practitioners.expand(signature.signer as Reference<Practitioner>, { abort });
    }
    return undefined;
  }

  async function expandGuardian() {
    return await fetchGuardian(store.relatedPeople, note.subject, abort);
  }

  async function expandInsurances() {
    const coverages = await fetchCoverages(store.coverages, note.subject, abort);
    return coverages.flatMap(c => c.payor.id ? c.payor : []);
  }

  async function expandEncounter() {
    return await store.encounters.expand(note.encounter, { abort });
  }

  async function expandAppointment(encounter: Encounter) {
    if (encounter.appointment) {
      const appointment = await store.appointments.expand(encounter.appointment, { abort });
      const staffAttendees = appointment.attendees.flatMap(a => a.subject.resourceType === "Practitioner" ? a.subject as GenericReference<Practitioner> : []);
      await store.practitioners.expand(staffAttendees);

      return appointment;
    }
    return undefined;
  }

  async function expandConditions(encounter: Encounter) {
    return await store.conditions.expand(encounter.diagnoses.map(g => g.condition), { abort });
  }

  async function expandGroupNote() {
    if (note.partOf) {
      const groupNote = await store.notes.expand(note.partOf, { abort });
      return groupNote as GroupNote;
    }
    return undefined;
  }

  async function expandForms(references: Reference<NoteSectionForm>[]) {
    await expandNoteSectionForms(store, references, abort);
  }

  async function expandProgram(encounter: Encounter) {
    if (encounter.program) {
      return await store.programs.expand(encounter.program, { abort });
    }
    return undefined;
  }

  async function expandLocation(encounter: Encounter) {
    if (encounter.location?.location) {
      return await store.locations.expand(encounter.location?.location, { abort });
    }
    return undefined;
  }

  async function expandServiceType(encounter: Encounter) {
    if (encounter.serviceType) {
      return await store.healthcareServices.expand(encounter.serviceType, { abort });
    }
    return undefined;
  }

  async function expandNoteDefinition() {
    return await store.noteDefinitions.expand(note.definition, { abort });
  }
}
