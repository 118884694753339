import { useMemo } from "react";
import {
  Appointment,
  AppointmentFilterSet,
  AppointmentSortField,
  Instant,
  InstantFilter,
  ReferenceFilter,
  SortField,
  ZonedDateTime
} from "@remhealth/apollo";
import { useStore } from "./useStore";
import { matchesReferenceFilter } from "./filters";

// Use to filter appointments relative to current time
export type OccurenceState = "Upcoming" | "Past";

export interface AppointmentFilterOptions {
  ownerId?: string;
  attendees?: ReferenceFilter[];
  occurrenceState?: OccurenceState;
  start: InstantFilter | undefined;
  excludeGroups?: boolean;
}

export function useAppointmentsView(sort: SortField<AppointmentSortField>, options: AppointmentFilterOptions) {
  const store = useStore();

  return useMemo(() => {
    return store.appointments.view({
      filters: {
        online: createAppointmentFilters(options),
        offline: s => offlinefilter(s, options),
      },
      orderBy: {
        online: sort,
        offline: sortOffline(sort),
      },
      feedOptions: options.ownerId && options.ownerId.length > 0
        ? { partition: options.ownerId }
        : { crossPartition: true },
    });
  }, [JSON.stringify([options, sort])]);
}

function createAppointmentFilters(options: AppointmentFilterOptions) {
  const { ownerId, attendees, start, excludeGroups } = options;

  const filterSet: AppointmentFilterSet = {};

  if (ownerId) {
    filterSet.owner = {
      in: [ownerId],
    };
  }

  if (start) {
    filterSet.start = start;
  }

  if (attendees && attendees.length > 0) {
    return attendees.map(attendeeFilter => ({
      ...filterSet,
      attendee: attendeeFilter,
    }));
  }

  if (excludeGroups) {
    filterSet.excludeGroups = true;
  }

  return [filterSet];
}

function offlinefilter(appointment: Appointment, options: AppointmentFilterOptions) {
  const { attendees, ownerId, start, excludeGroups } = options;

  if (ownerId && appointment.owner.id !== ownerId) {
    return false;
  }

  if (attendees && attendees.length > 0) {
    const attendeeIds = new Set(appointment.attendees.map(i => i.subject.id));

    if (!attendees.some(filter => matchesReferenceFilter(attendeeIds, filter))) {
      return false;
    }
  }

  if (excludeGroups && appointment.isGroup) {
    return false;
  }

  const appointmentStart = ZonedDateTime.toDate(appointment.start);

  if (start?.before && appointmentStart > Instant.toDate(start.before)) {
    return false;
  }

  if (start?.after && appointmentStart < Instant.toDate(start.after)) {
    return false;
  }

  return true;
}

function sortOffline(sort: SortField<AppointmentSortField>) {
  const dir = sort.direction === "Ascending" ? 1 : -1;
  return (a: Appointment, b: Appointment) => {
    switch (sort.field) {
      case "Start":
      default:
        return dir * (a.start ?? "").localeCompare(b.start ?? "");
    }
  };
}
