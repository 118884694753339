import { useEffect, useState } from "react";
import type { NoteRule } from "@remhealth/apollo";
import { type NoteRuleFilterOptions, useFeed, useNoteRulesView } from "@remhealth/core";
import { useAbort, useDebouncer } from "@remhealth/ui";

export function useNoteRulesFeed(options: NoteRuleFilterOptions, enabled = true): NoteRule[] {
  const abort = useAbort();
  const debouncer = useDebouncer(100);

  const view = useNoteRulesView(options);

  const feed = useFeed(view, { reload: false });

  const [noteRules, setNoteRules] = useState<NoteRule[]>([]);

  useEffect(() => {
    if (enabled) {
      debouncer.delay(loadRules);
    }
  }, [enabled, feed.session]);

  return noteRules;

  async function loadRules() {
    const signal = abort.signal;
    while (feed.canLoadMore && !signal.aborted) {
      await feed.loadMore(100, signal);
    }
    setNoteRules(feed.items);
  }
}
