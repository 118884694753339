import { createContext, useContext } from "react";
import { Toaster, createSubscription, voidNotifier } from "@remhealth/ui";
import { Identifier } from "@remhealth/apollo";
import type { ErrorContext } from "@remhealth/host";

export interface AppContext {
  readonly notification: Toaster;
  identifierUrl(identifier: Identifier): string | undefined;
  handleError(error: any, context?: ErrorContext): void;
  reportError(error: any, context?: ErrorContext): void;
}

export const AppContext = createContext<AppContext>({
  notification: voidNotifier,
  identifierUrl: () => undefined,
  handleError: () => {},
  reportError: () => {},
});

export const useApp = () => useContext(AppContext);
export const useErrorHandler = () => useContext(AppContext).handleError;
export const useErrorReporter = () => useContext(AppContext).reportError;
export const useNotifier = () => useContext(AppContext).notification;

export interface ActivePullState {
  appointments: boolean;
  appointmentIds: Set<string>;
  personIds: Set<string>;
  noteSectionFormIds: Set<string>;
}

export const { context: ActivePullContext, Provider: ActivePullProvider } = createSubscription<ActivePullState>({
  appointments: false,
  appointmentIds: new Set<string>(),
  personIds: new Set<string>(),
  noteSectionFormIds: new Set<string>(),
});
