import { Approximate, CompositionStatus, Encounter, Note, ParticipantRole, VisitStatus } from "@remhealth/apollo";
import { Divider, Ellipsize, Intent, Tag } from "@remhealth/ui";
import { useDateFormatter } from "@remhealth/host";
import { useLabeling } from "@remhealth/core";
import { Text } from "~/text";
import { getVisitStatus } from "~/utils";
import { getEnrollmentAndProgramValue } from "~/notes/utils";
import {
  NoteInfo,
  NoteInfoContainer,
  NoteInfoDescription,
  NoteInfoTitle,
  NoteStatus,
  NoteTypeInfo,
  NoteTypeTitle,
  SessionDateTime,
  SessionInfo,
  SessionTimeHeaderContent,
  Time
} from "./noteContentHeaderInfo.styles";

export interface NoteContentHeaderInfoProps {
  note: Note;
  encounter: Encounter;
  className?: string;
}

export const NoteContentHeaderInfo = (props: NoteContentHeaderInfoProps) => {
  const { className, note, encounter } = props;

  const dates = useDateFormatter();
  const labels = useLabeling();

  const secondaryPerformer = encounter.participants.find(p => p.role === ParticipantRole.SecondaryPerformer)?.individual;
  const owner = note.participants.find(p => p.role === ParticipantRole.PrimaryPerformer);
  const isDraft = note.status === CompositionStatus.Preliminary;
  const { start, end } = encounter.period;
  const isShowStatus = getVisitStatus(encounter.status) === VisitStatus.Show;
  return (
    <NoteInfoContainer className={className}>
      <NoteTypeInfo>
        <NoteTypeTitle>
          {note.definition.display}
        </NoteTypeTitle>
        {isDraft && (
          <NoteStatus>
            <Tag minimal intent={Intent.PRIMARY}>
              {Text.Draft}
            </Tag>
          </NoteStatus>
        )}
      </NoteTypeInfo>
      <SessionInfo className="session-info">
        {practitionerRenderer()}
        <Divider />
        {encounter.location
        && (
          <>
            {renderHeaderSection(labels.Location, encounter.location?.location.display)}
            <Divider />
          </>
        )}
        {encounter.serviceType
        && (
          <>
            {renderHeaderSection(labels.ServiceType, encounter.serviceType?.display)}
            <Divider />
          </>
        )}
        {renderHeaderSection(Text.EnrollmentProgramLabel(labels), getEnrollmentAndProgramValue(encounter))}
        {note.category?.text && (
          <>
            <Divider />
            {renderHeaderSection(labels.NoteCategory, note.category.text)}
          </>
        )}
        {isShowStatus && (
          <>
            <Divider />
            <SessionTimeHeaderContent>
              <NoteInfo className="note-info">
                <NoteInfoTitle>{Text.SessionDateOrTime(labels)}</NoteInfoTitle>
                <SessionDateTime>{dates.date(Approximate.toDate(start))}</SessionDateTime>
                <Time>{dates.friendlyTimeRange(Approximate.toDate(start), Approximate.toDate(end))}</Time>
              </NoteInfo>
            </SessionTimeHeaderContent>
          </>
        )}
      </SessionInfo>
    </NoteInfoContainer>
  );

  function practitionerRenderer() {
    return (
      <NoteInfo className="note-info">
        <NoteInfoTitle>{secondaryPerformer ? labels.Practitioners : labels.Practitioner}</NoteInfoTitle>
        <NoteInfoDescription>
          {owner && (
            <Ellipsize block lines={secondaryPerformer ? 1 : 2}>
              {owner.individual.display}
            </Ellipsize>
          )}
          {secondaryPerformer && (
            <Ellipsize block lines={owner ? 1 : 2}>
              {secondaryPerformer.display}
            </Ellipsize>
          )}
        </NoteInfoDescription>
      </NoteInfo>
    );
  }

  function renderHeaderSection(title: string | JSX.Element, value: string | undefined) {
    return (
      <NoteInfo className="note-info">
        <NoteInfoTitle>{title}</NoteInfoTitle>
        <NoteInfoDescription>
          <Ellipsize block lines={2}>{value}</Ellipsize>
        </NoteInfoDescription>
      </NoteInfo>
    );
  }
};
