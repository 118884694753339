import { type NoteSectionForm, QuestionnaireElement, QuestionnaireElementType, type Reference } from "@remhealth/apollo";

export enum QuestionFlavor {
  Duration = "Duration",
  SingleChoice = "SingleChoice",
  MultiChoice = "MultiChoice",
  MultilineText = "MultilineText",
  SinglelineText = "SinglelineText",
  Integer = "Integer",
  Decimal = "Decimal",
  Instructions = "Instructions",
  ParagraphBreak = "ParagraphBreak",
  Ranking = "Ranking",
  Checkbox = "Checkbox",
  YesNo = "YesNo",
  Date = "Date",
  DateTime = "DateTime",
  Time = "Time",
  StaffSearch = "StaffSearch",
  EhrLookup = "EhrLookup",
  Calculated = "Calculated",
  Signature = "Signature",
  Content = "Content",
  Header = "Header",
  Grid = "Grid",
  SingleDecision = "SingleDecision",
  MultiDecision = "MultiDecision",
  ServiceSearch = "ServiceSearch",
  UsStateLookup = "UsStateLookup",
}

const allFlavors = Object.keys(QuestionFlavor) as QuestionFlavor[];

export const questionFlavorDisplay: Record<QuestionFlavor, string> = {
  Duration: "Duration",
  SingleChoice: "Single Choice",
  MultiChoice: "Multi Choice",
  MultilineText: "Long Text",
  SinglelineText: "Short Text",
  Instructions: "Instructions",
  ParagraphBreak: "Paragraph Break",
  Ranking: "Ranked List",
  Checkbox: "Checkbox",
  YesNo: "Yes/No",
  Date: "Date",
  DateTime: "Date Time",
  Time: "Time",
  Integer: "Numeric",
  Decimal: "Numeric",
  StaffSearch: "Staff Search",
  EhrLookup: "Lookup",
  Calculated: "Calculated",
  Signature: "Signature",
  Content: "Content",
  Header: "Header",
  Grid: "Grid",
  SingleDecision: "Decision Only",
  MultiDecision: "Decision Only Multi Choice",
  ServiceSearch: "Service Search",
  UsStateLookup: "US State Lookup",
};

export const stackableQuestionFlavors: Record<QuestionFlavor, boolean> = {
  // Stackable
  Duration: true,
  SinglelineText: true,
  Checkbox: true,
  YesNo: true,
  Date: true,
  DateTime: true,
  Time: true,
  Integer: true,
  Decimal: true,
  StaffSearch: true,
  EhrLookup: true,
  Signature: true,
  SingleDecision: true,
  ServiceSearch: true,
  UsStateLookup: true,

  // Not stackable
  SingleChoice: false,
  MultiChoice: false,
  MultilineText: false,
  Instructions: false,
  ParagraphBreak: false,
  Ranking: false,
  Calculated: false,
  Content: false,
  Header: false,
  Grid: false,
  MultiDecision: false,
};

export const templateAllowedFlavors: Record<QuestionFlavor, boolean> = {
  // Allowed
  MultiChoice: true,
  YesNo: true,
  SingleChoice: true,
  Ranking: true,
  Checkbox: true,
  SinglelineText: true,
  MultilineText: true,
  Integer: true,
  SingleDecision: true,
  MultiDecision: true,

  // Not allowed
  Decimal: false,
  Instructions: false,
  ParagraphBreak: false,
  StaffSearch: false,
  EhrLookup: false,
  Calculated: false,
  Signature: false,
  Content: false,
  Header: false,
  Date: false,
  DateTime: false,
  Time: false,
  Duration: false,
  Grid: false,
  ServiceSearch: false,
  UsStateLookup: false,
};

export const unanswerableQuestionFlavors = new Set([
  QuestionFlavor.Instructions,
  QuestionFlavor.ParagraphBreak,
  QuestionFlavor.Calculated,
  QuestionFlavor.Content,
  QuestionFlavor.Header,
]);

export const answerableQuestionFlavors = new Set(allFlavors.filter(t => !unanswerableQuestionFlavors.has(t)));
export const producesNarrativeQuestionFlavors = new Set([...answerableQuestionFlavors.values()].filter(t => t !== QuestionFlavor.SingleDecision && t !== QuestionFlavor.MultiDecision));

export const subQuestionAllowedQuestionFlavors = new Set([QuestionFlavor.Checkbox]);

export const questionFlavorsWithAnswerOptions = new Set([
  QuestionFlavor.MultiChoice,
  QuestionFlavor.SingleChoice,
  QuestionFlavor.YesNo,
  QuestionFlavor.Ranking,
  QuestionFlavor.SingleDecision,
  QuestionFlavor.MultiDecision,
]);

export const nonTrailingParagraphBreak = new Set([
  QuestionFlavor.MultilineText,
  QuestionFlavor.Instructions,
  QuestionFlavor.Ranking,
  QuestionFlavor.Signature,
  QuestionFlavor.Header,
  QuestionFlavor.Grid,
  QuestionFlavor.SingleDecision,
  QuestionFlavor.MultiDecision,
]);

export const questionFlavorsWithAnswerOutput = new Set([
  QuestionFlavor.MultiChoice,
  QuestionFlavor.SingleChoice,
  QuestionFlavor.YesNo,
  QuestionFlavor.Ranking,
]);

export const questionFlavorsWithoutOutput = new Set([
  QuestionFlavor.Header,
  QuestionFlavor.SingleDecision,
  QuestionFlavor.MultiDecision,
  QuestionFlavor.Grid,
  QuestionFlavor.Instructions,
  QuestionFlavor.ParagraphBreak,
  QuestionFlavor.Content,
]);

export const questionFlavorsOptionalText = new Set([
  QuestionFlavor.ParagraphBreak,
  QuestionFlavor.Grid,
]);

export const questionFlavorsWithNoPatientView = new Set([
  QuestionFlavor.Signature,
  QuestionFlavor.Instructions,
  QuestionFlavor.Header,
  QuestionFlavor.SingleDecision,
  QuestionFlavor.MultiDecision,
]);

export const durationCalculateQuestionFlavors = new Set([QuestionFlavor.DateTime, QuestionFlavor.Time]);
export const numericCalculateQuestionFlavors = new Set([
  QuestionFlavor.MultiChoice,
  QuestionFlavor.SingleChoice,
  QuestionFlavor.Integer,
  QuestionFlavor.Decimal,
]);

export const calculationAllowedQuestionFlavors = new Set([
  ...numericCalculateQuestionFlavors,
  ...durationCalculateQuestionFlavors,
]);

export const plainTextQuestionFlavors = new Set([
  QuestionFlavor.Header,
  QuestionFlavor.Grid,
]);

export function resolveQuestionFlavor(element: QuestionnaireElement): QuestionFlavor {
  switch (element.type) {
    case QuestionnaireElementType.TextChoice:
    case QuestionnaireElementType.Choice:
    case QuestionnaireElementType.OpenChoice:
      return resolveChoiceFlavor(element);
    case QuestionnaireElementType.TextBlock: {
      return resolveTextBlockQuestionnaireElementType(element);
    }
    case QuestionnaireElementType.Boolean: {
      if (element.answerOptions.length === 2) {
        return QuestionFlavor.YesNo;
      }
      return QuestionFlavor.Checkbox;
    }

    case QuestionnaireElementType.String: return QuestionFlavor.SinglelineText;
    case QuestionnaireElementType.Ranking: return QuestionFlavor.Ranking;
    case QuestionnaireElementType.Text: return QuestionFlavor.MultilineText;
    case QuestionnaireElementType.Date: return QuestionFlavor.Date;
    case QuestionnaireElementType.DateTime: return QuestionFlavor.DateTime;
    case QuestionnaireElementType.Integer: return QuestionFlavor.Integer;
    case QuestionnaireElementType.Decimal: return QuestionFlavor.Decimal;
    case QuestionnaireElementType.Time: return QuestionFlavor.Time;
    case QuestionnaireElementType.Duration: return QuestionFlavor.Duration;
    case QuestionnaireElementType.Practitioner: return QuestionFlavor.StaffSearch;
    case QuestionnaireElementType.EhrLookup: return QuestionFlavor.EhrLookup;
    case QuestionnaireElementType.Signature: return QuestionFlavor.Signature;
    case QuestionnaireElementType.Header: return QuestionFlavor.Header;
    case QuestionnaireElementType.Grid: return QuestionFlavor.Grid;
    case QuestionnaireElementType.ComputeAddition:
    case QuestionnaireElementType.ComputeDuration:
    case QuestionnaireElementType.ComputeMultiplication:
      return QuestionFlavor.Calculated;
    case QuestionnaireElementType.HealthcareService: return QuestionFlavor.ServiceSearch;
    case QuestionnaireElementType.UsState: return QuestionFlavor.UsStateLookup;

    // Unsupported types
    case QuestionnaireElementType.Address:
    case QuestionnaireElementType.Approximate:
    case QuestionnaireElementType.ContactPoint:
    case QuestionnaireElementType.InsuranceCompany:
    case QuestionnaireElementType.Location:
    case QuestionnaireElementType.Patient:
    case QuestionnaireElementType.RelatedPerson:
      return QuestionFlavor.SinglelineText;
  }
}

export function isSingleSelectType(questionFlavor: QuestionFlavor) {
  return questionFlavor === QuestionFlavor.SingleChoice
    || questionFlavor === QuestionFlavor.SingleDecision;
}

function resolveTextBlockQuestionnaireElementType(element: QuestionnaireElement) {
  switch (element.presentationHint) {
    case "InformationalCallout": return QuestionFlavor.Instructions;
    case "ParagraphBreak": return QuestionFlavor.ParagraphBreak;
    default: return QuestionFlavor.Content;
  }
}

function resolveChoiceFlavor(element: QuestionnaireElement) {
  if (element.answerMinimum === 1 && element.answerMaximum === 1) {
    if (element.output?.plainText === undefined && element.output?.value === undefined) {
      return QuestionFlavor.SingleDecision;
    }
    return QuestionFlavor.SingleChoice;
  }

  if (element.output === undefined) {
    return QuestionFlavor.MultiDecision;
  }
  return QuestionFlavor.MultiChoice;
}

export function isGroupableQuestion(question: QuestionnaireElement): boolean {
  // Label must be short enough
  if (question.text?.plainText?.length && question.text.plainText.length > 150) {
    return false;
  }

  const flavor = resolveQuestionFlavor(question);

  // Dropdowns can stack
  if (flavor === QuestionFlavor.SingleChoice && question.presentationHint === "Dropdown") {
    return true;
  }

  return stackableQuestionFlavors[flavor];
}

export function hasLinkedSectionForm(question: QuestionnaireElement): question is QuestionnaireElement & { form: Reference<NoteSectionForm> } {
  const flavor = resolveQuestionFlavor(question);

  return flavor === QuestionFlavor.MultilineText && !!question.form;
}
