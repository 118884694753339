import { generatePath, useMatch } from "react-router-dom";

export type ScribbleType = "personal" | "shared";

const clinicalRoot = "/clinical";
const agendaRoot = "/agenda";
const settingsRoot = "/settings";

export const scribbleRoutes = {
  scribbles: "/scribbles",
  sharedScribbles: "/shared-scribbles",
  scribble: "/scribbles/:scribbleId",
  sharedScribble: "/shared-scribbles/:scribbleId",
} as const;

export const clinicalRoutes = {
  clinical: clinicalRoot,
  agenda: agendaRoot,
  chart: `${clinicalRoot}/chart/:tab`,
  bulkSign: `${clinicalRoot}/bulk-sign`,
  patient: `${clinicalRoot}/:patientId`,
  patientTab: `${clinicalRoot}/:patientId/:tab`,
  viewNote: `${clinicalRoot}/:patientId/notes/:noteId`,
  editNote: `${clinicalRoot}/:patientId/notes/:noteId/edit`,
  groups: `${settingsRoot}/groups`,
} as const;

export const defaultChartTabId = "open-sessions";
export const defaultChartTabPath = generatePath(clinicalRoutes.chart, { tab: defaultChartTabId });

// Hook to avoid the confusion with some of the clinical paths
export function usePatientRouteId() {
  const isBulkSignArea = !!useMatch(`${clinicalRoutes.bulkSign}/*`);
  const isChartHome = useMatch(`${clinicalRoutes.chart}/*`);
  const patientRouteMatch = useMatch(`${clinicalRoutes.patient}/*`);
  if (isBulkSignArea || isChartHome) {
    return undefined;
  }

  return patientRouteMatch?.params.patientId;
}
