import styled from "styled-components";
import { Alert } from "@remhealth/ui";

interface AlertDialogProps {
  $isNoteDeleted: boolean;
}

export const AlertDialog = styled(Alert) <AlertDialogProps>`
  .bp5-alert-contents {
    width: 100%;
  }

  .bp5-alert-body {
    height: ${props => props.$isNoteDeleted ? "auto" : "80px"};
  }
`;
