import { AuthorizationServiceConfiguration } from "@openid/appauth";
import type { OAuthConfiguration } from "../configuration";

export type LogoutRequestHandler = (onSuccess: () => void, logoutUrl: string) => void;

export interface LogoutRequest {
  redirectUri: string;
  customLogoutEndpoint: string;
  idToken: string | null;
}

export class LogoutManager {
  private readonly configuration: OAuthConfiguration;
  private readonly metadata: AuthorizationServiceConfiguration;
  private readonly handler: LogoutRequestHandler;

  constructor(
    configuration: OAuthConfiguration,
    metadata: AuthorizationServiceConfiguration,
    handler: LogoutRequestHandler
  ) {
    this.configuration = configuration;
    this.metadata = metadata;
    this.handler = handler;
  }

  /*
    * Invoke the system browser to log the user out
    */
  /* eslint-disable no-async-promise-executor */
  public async start(request: LogoutRequest): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // Try to start the logout
        await this.startLogout(request, resolve, reject);
      } catch (error) {
        // Handle any error conditions
        reject(error);
      }
    });
  }

  /*
    * Do the work to start the logout
    */
  /* eslint-disable @typescript-eslint/no-unused-vars */
  private async startLogout(request: LogoutRequest, onSuccess: () => void, onError: (e: any) => void): Promise<void> {
    try {
      // First build the logout URL
      const logoutUrl = this.getLogoutUrlBuilder(request).buildUrl();

      // Ask the main side of the app to open the system browser
      this.handler(onSuccess, logoutUrl);
    } catch (error) {
      // Capture errors
      onError(error);
    }
  }

  /*
    * Get a builder object to deal with vendor specific behaviour in Cognito
    */
  private getLogoutUrlBuilder(request: LogoutRequest): LogoutUrlBuilder {
    if (this.configuration.authority.toLowerCase().includes("cognito")) {
      return new CognitoLogoutUrlBuilder(this.configuration, request.customLogoutEndpoint, request.redirectUri);
    }
    return new StandardLogoutUrlBuilder(this.metadata, request.redirectUri, request.idToken);
  }
}

interface LogoutUrlBuilder {
  buildUrl(): string;
}

class CognitoLogoutUrlBuilder implements LogoutUrlBuilder {
  private readonly configuration: OAuthConfiguration;
  private readonly customLogoutEndpoint: string;
  private readonly redirectUri: string;

  constructor(configuration: OAuthConfiguration, customLogoutEndpoint: string, redirectUri: string) {
    this.configuration = configuration;
    this.customLogoutEndpoint = customLogoutEndpoint;
    this.redirectUri = redirectUri;
  }

  public buildUrl(): string {
    const logoutReturnUri = encodeURIComponent(this.redirectUri);
    const clientId = encodeURIComponent(this.configuration.clientId);
    return `${this.customLogoutEndpoint}?client_id=${clientId}&logout_uri=${logoutReturnUri}`;
  }
}

class StandardLogoutUrlBuilder implements LogoutUrlBuilder {
  private readonly metadata: AuthorizationServiceConfiguration;
  private readonly redirectUri: string;
  private readonly idToken: string | null;

  constructor(
    metadata: AuthorizationServiceConfiguration,
    redirectUri: string,
    idToken: string | null
  ) {
    this.metadata = metadata;
    this.redirectUri = redirectUri;
    this.idToken = idToken;
  }

  /*
   * Form the standards based URL using the end session endpoint
   */
  public buildUrl(): string {
    const endSessionUrl = this.metadata.endSessionEndpoint;
    const postLogoutRedirectUri = encodeURIComponent(this.redirectUri);
    const encodedIdQuery = this.idToken ? `&id_token_hint=${encodeURIComponent(this.idToken)}` : "";
    return `${endSessionUrl}?post_logout_redirect_uri=${postLogoutRedirectUri}${encodedIdQuery}`;
  }
}
