// eslint-disable-next-line no-restricted-imports
export * from "@remhealth/core/src/text/text";

import { Labeling } from "@remhealth/core";
import { DateFormats } from "@remhealth/ui";

// Buttons
export const AddSignature = "Add Signature";
export const AddToGroupNote = "Add to Group Note";
export const ApplyConfiguration = "Apply Configuration";
export const CancelAppointment = "Cancel Appointment";
export const CancelSessionEdit = "Cancel";
export const CancelSignNote = "Cancel";
export const CancelSwitchNote = "No, stay on this note";
export const CancelSwitchPatientAndNote = (patient: string) => `No, stay on ${patient}`;
export const ConfirmChangePineedList = "Yes, change pinned list";
export const ConfirmSwitchNote = "Yes, switch notes";
export const ConfirmSwitchPatientAndNote = (patient: string) => `Yes, switch to ${patient}`;
export const Continue = "Continue";
export const Create = "Create";
export const CreateNewAppointment = "No, create new appointment";
export const CreatePlan = "Create Plan";
export const ConfirmNarrativeConversion = "Yes, convert to narrative";
export const DeselectAll = "Deselect All";
export const DiscardBackup = "Yes, discard back-up";
export const EditNote = "Edit Note";
export const FixIt = "Yes, let's fix it";
export const LeaveThem = "No, leave them";
export const LinkNoteType = "Link Note Type";
export const NoRecentDraftNotes = "You do not have any recent drafts.";
export const Preview = "Preview";
export const PreviewNote = "Preview Note";
export const Print = "Print";
export const Reassign = "Yes, reassign";
export const RemoveAll = "Remove All";
export const RemoveFinished = "Remove, I'm Finished";
export const RemoveSignatures = "Yes, remove signatures";
export const RemoveSignature = "Yes, remove signature";
export const Reviewed = "Yes, I reviewed";
export const RouteNote = "Note Routing";
export const SaveAndContinueWriting = "Save and continue writing";
export const SaveAndFinishLater = "Save and finish later";
export const Select = "Select";
export const SelectAll = "Select All";
export const SelectAllVisible = "Select All Visible";
export const SelectRequiredOnly = "Select Required Only";
export const Sign = "Sign";
export const SignNote = "Sign Note";
export const StartNote = "Start Note";
export const StartDocumentationNote = "Start Documentation Note";
export const StartAssesssmentNote = "Start Assesssment Note";
export const StartNoteForAll = "Start Note for All";
export const StartPacket = "Start Packet";
export const TieToAppointment = "Yes, tie to existing appointment";
export const Today = "Today";
export const UnsignedNotes = "Unsigned Notes";
export const UnselectAll = "Unselect All";
export const UnselectAllVisible = "Unselect All Visible";
export const UnselectRequired = "Unselect Required";

// Errors
export const LoadPacketFailure = "There was an issues loading the packet";
export const DateFormatError = `Oops, looks like the date or time format is incorrect. Example supported formats: ${DateFormats.dateTime(new Date(), true)} or ${DateFormats.dateTime(new Date(), false)}`;
export const CustomNoteQuestionEmpty = "Question is a required field";

// Labels
export const AddOn = "Add-On";
export const Address = "Address";
export const Adjectives = "Adjectives";
export const Advanced = "Advanced";
export const Agenda = "Agenda";
export const AllowAdditionalSignatures = "Allow additional signers";

export const Alternatives = "Alternatives";
export const AuditSummary = "Audit Summary";
export const Author = "Author";
export const Appointments = "Appointments";
export const AppointmentStatus = "Appointment Status";
export const AlwaysMaskCalls = "Always Mask Calls";
export const AssessmentNoteType = "Assessment Note Type";
export const AssessmentNoteTypes = "Assessment Note Types";
export const CareTeam = "Care Team";
export const ClinicalQualityIndicator = "Clinical Quality Indicator";
export const ClinicalSignature = "Clinical Signature";
export const CancelledStatus = "Cancelled Status";
export const Comment = "Comment";
export const Comments = "Comments";
export const Contact = "Contact";
export const ConfigureGroupNoteTypes = "Configure Group Note Types";
export const Correctness = "Correctness";
export const CreatedDate = "Created Date";
export const CreateToSign = "Create to Sign";
export const CustomNoteSection = "Custom Note Section";
export const CustomNoteSectionName = "Name";
export const CustomNoteSections = "Custom Note Sections";
export const ClinicalRecommendations = "Clinical Recommendations";
export const ConvertToNarrative = "Convert to Narrative";
export const DateOfBirthWithAge = "Birthdate (Age)";
export const Deadline = "Deadline";
export const Diagnoses = "Diagnoses";
export const Diagnosis = "Diagnosis";
export const DraftNotes = "Draft Notes";
export const DraftsOnly = "Drafts Only";
export const Duration = "Duration";
export const DropIns = "Drop-Ins";
export const Email = "Email";
export const Emails = "Emails";
export const EmergencyContact = "Emergency Contact";
export const EmergencyContacts = "Emergency Contacts";
export const EnterPinForClinicianView = "Enter pin to return to clinician view";
export const Expansion = "Expansion";
export const Expansions = "Expansions";
export const ExpiredPatient = "Expired Patient";
export const ExpiredPractitioner = (labels: Labeling) => `Expired ${labels.Practitioner}`;
export const ExportingAppointments = "Exporting Appointments";
export const EvidenceBasedPractices = "Evidence-Based Practices";
export const GuardianOrRepresentative = "Guardian/Representative";
export const Gender = "Gender";
export const GenderIdentity = "Gender Identity";
export const Goal = "Goal";
export const Goals = "Goals";
export const GoalTracking = "Goal Tracking";
export const Group = "Group";
export const GroupName = "Group Name";
export const GroupNoteType = "Group Note Type";
export const GroupNoteTypes = "Group Note Types";
export const Groups = "Groups";
export const GroupsConfigured = "Groups Configured";
export const Guardian = "Guardian";
export const History = "History";
export const Home = "Home";
export const Import = (label: string) => `Import ${label}`;
export const Imports = "Imports";
export const Instructions = "Instructions";
export const Insurance = "Insurance";
export const IncludeInPatientView = (labels: Labeling) => `Include in ${labels.patient} view`;
export const LastAttempt = "Last Attempt";
export const LengthOfSession = "Length of Session";
export const LevelOfCare = "Level of Care";
export const Locked = "Locked";
export const Mobile = "Mobile";
export const MobileCallMasking = "Mobile Call Masking";
export const NeedsEHRResolution = "Needs EHR Resolution";
export const NewScribble = "New Scribble";
export const NoPatients = (labels: Labeling) => `No ${labels.patients}`;
export const NoteAppointmentLabel = "Note Addresses Which Existing Service/Appointment";
export const NoteOutput = "Note Output";
export const Notes = "Notes";
export const NoteTemplate = "Note Template";
export const NoteTemplates = "Note Templates";
export const NoteType = "Note Type";
export const NoteTypes = "Note Types";
export const OpenSessions = (labels: Labeling) => `Open ${labels.Sessions}`;
export const OptionsSelected = "Options Selected";
export const OverrideServiceType = (labels: Labeling) => `Override ${labels.ServiceType}`;
export const Packet = "Packet";
export const Packets = "Packets";
export const PastNotes = "Past Notes";
export const PatientDiagnoses = (labels: Labeling) => `${labels.Patient} Diagnoses`;
export const PatientGoals = (labels: Labeling) => `${labels.Patient} Goals`;
export const Personal = "Personal";
export const Phone = "Phone";
export const PlaceOfService = "Place of Service";
export const Policy = "Policy";
export const PreferredContact = "Preferred Contact";
export const PrintDate = "Printed";
export const ProblemList = (labels: Labeling) => `${labels.Problem} List`;
export const ProgramFilter = "Program Filter";
export const QuestionPreview = "Question Preview";
export const Questions = "Questions";
export const Quality = "Quality";
export const RecentScribble = "Recent Scribbles";
export const RelationShip = "Relationship";
export const RemoveFromGroupNote = "Remove from Group Note";
export const RuleDistribution = "Rule Distribution";
export const Scribble = "Scribble";
export const Scribbles = "Scribbles";
export const Sections = "Sections";
export const SelectNoteTemplate = "Select Note template";
export const SelectNoteType = "Select Note type";
export const SendMonthlyUsageEmail = "Send monthly usage email";
export const ServiceStart = "Service Start";
export const ServiceStop = "Service Stop";
export const ServiceDuration = "Service Duration";
export const SessionDate = (labels: Labeling) => `${labels.Session} Date`;
export const SessionDateOrTime = (labels: Labeling) => `${labels.Session} Date/Time`;
export const SessionDetails = (labels: Labeling) => `${labels.Session} Details`;
export const SessionInformation = (labels: Labeling) => `${labels.Session} Information`;
export const SessionStatus = (labels: Labeling) => `${labels.Session} Status`;
export const SessionToSign = (labels: Labeling) => `${labels.Session} to Sign`;
export const Shared = "Shared";
export const ShareScribble = "Share Scribble";
export const SharedScribble = "Shared Scribble";
export const SharedScribbles = "Shared Scribbles";
export const Signature = "Signature";
export const Signatures = "Signatures";
export const SignatureConfiguration = "Signature Configuration";
export const SignatureDate = "Date";
export const SignatureType = "Signature Type";
export const SignedDate = "Signed Date";
export const SignedNotes = "Signed Notes";
export const SignedToday = "Signed Today";
export const Start = "Start";
export const StartGoalTracking = "Start Goal Tracking";
export const Stop = "Stop";
export const SyncIssues = "Sync Issues";
export const Summary = "Summary";
export const Supervises = "Supervises";
export const SpeedTools = "Speed Tools";
export const SpellingAndGrammarCheck = "Spelling and Grammar check";
export const Template = "Template";
export const Templates = "Templates";
export const TextBlock = "Text Block";
export const TimeSinceSession = "Time since session";
export const Transcriptions = "Transcriptions";
export const TreatmentPlan = "Treatment Plan";
export const Units = "Units";
export const Unlock = "Unlock";
export const Upcoming = "Upcoming";
export const UserDistribution = (labels: Labeling) => `${labels.Practitioner} Distribution`;
export const UseEBPElements = "Use Evidence-Based Practice Elements";
export const WhoIsSigning = "Who is signing";
export const Work = "Work";
export const Worklist = "Worklist";

// Placeholders
export const AssociateScribblePatient = (labels: Labeling) => `Associate a ${labels.patient}`;
export const CustomNoteSectionNamePlaceholder = "Name";
export const NoteTextSectionPlaceholder = "Start typing...";
export const SearchCustomNoteSections = `Search ${CustomNoteSections.toLowerCase()}...`;
export const SearchDiagnoses = (labels: Labeling) => `Search the ${labels.patient}'s active diagnoses...`;
export const SearchLocations = (labels: Labeling) => `Search ${labels.locations}...`;
export const SearchNotes = "Search progress notes...";
export const SearchPackets = "Search packets...";
export const SearchPatients = (labels: Labeling) => `Search ${labels.patients}...`;
export const SearchPatientGroupNote = (patientLabel: string) => `Search ${patientLabel} to add to group note...`;
export const SearchTherapistGroupNote = "Search practitioners to add to group note...";
export const SearchPrograms = "Search programs...";
export const SearchScribbles = "Search scribbles...";
export const SearchServiceTypes = (labels: Labeling) => `Search ${labels.serviceTypes}...`;
export const SearchSharedScribbles = "Search shared scribbles...";
export const SearchTemplates = "Search templates...";
export const TemplateDescriptionPlaceHolder = "Describe when this template should be used (optional).";
export const TemplateNamePlaceHolder = "Name for the template";

// Tooltips
export const RemoveRecentPatient = (labels: Labeling) => `Remove from recent ${labels.patients}`;
export const AddTopics = "Add Topics";
export const AnswerPlaceholderTooltips = "The answers provided will appear at this location in the note output statement.";
export const AllDiagnosesAdded = "All diagnoses have been added.";
export const CNSMissingTextSection = "The Note Type this Custom Note Section is linked to does not include a text section, which is required in myAvatar.";
export const ClinicalRecommendationsSuggested = "Clinical Recommendations Suggested:";
export const ClinicalRecommendationsAccepted = "Clinical Recommendations Accepted:";
export const DisableShareForScribble = "Scribbles linked to a session cannot be shared";
export const LeaveShareScribble = "Leave shared scribble";
export const UserCannotAccessPatient = (labels: Labeling) => `The user does not have access to the ${labels.patient}.`;
export const Primary = "Primary";
export const PreviewSessions = "Preview Sessions";
export const PreviewTopics = "Preview Topics";
export const SearchNoteTypes = `Search ${NoteTypes.toLowerCase()}...`;
export const SearchArchivedNoteTypes = `Search archived ${NoteTypes.toLowerCase()}...`;
export const SearchAssessmentNoteTypes = `Search assessment ${NoteTypes.toLowerCase()}...`;
export const SearchArchivedAssessmentNoteTypes = `Search archived assessment ${NoteTypes.toLowerCase()}...`;
export const SearchGroupNoteTypes = `Search ${GroupNoteTypes.toLowerCase()}...`;
export const EmptyPreviewSessions = "There are no active topics for the group. These can be configured in CT|One.";
export const SpellingAndGrammarSuggested = "Spelling and Grammar Suggested:";
export const SpellingAndGrammarAccepted = "Spelling and Grammar Accepted:";
export const EmptyPreviewTopics = (labels: Labeling) => `There are no active topics for the group. These can be configured in ${labels.Product}.`;
export const FailedBefore = "This note failed to sign in a previous Bulk Sign. Click to review and sign again.";
export const LinkedSessionSharedScribble = "Scribbles linked to a session cannot be shared.";
export const EditSessionInfo = (labels: Labeling) => `Edit ${labels.session} information`;
export const NoteSignTimeoutWarning = (labels: Labeling) => `This note has exceeded the preferred ${SessionToSign(labels)} threshold.`;
export const NoteSignTimeoutWarningRemaining = (labels: Labeling, remainingDuration: string) => `${remainingDuration} remaining until this note exceeds the preferred ${SessionToSign(labels)} threshold. `;

// Window Titles
export const AddPatients = (labels: Labeling) => `Add ${labels.Patients}`;
export const AddTherapist = (labels: Labeling) => `Add ${labels.Practitioner}`;
export const GroupSessionInformation = "Group Session Information";
export const IncompleteNote = "Incomplete Note";
export const ManageGroup = "Manage Group";
export const NotePreview = "Note Preview";
export const PatientNotePreview = (labels: Labeling) => `${labels.Patient} Note Preview`;
export const RemovePatient = (labels: Labeling) => `Remove Tagged ${labels.Patient}`;
export const UnableStartNote = "Unable to start a note";
export const UnfinishedPacket = "Incomplete Packet";

// Messages
export const AgendaWidgetEmptyList = (value: string) => `Looks like you don't have appointments scheduled for ${value}.`;
export const AppointmentInviteError = (name: string, reason?: string) => `Unable to add ${name} to the group appointment.${reason ? ` ${reason}` : ""}`;
export const AppointmentUninviteError = (name: string, reason?: string) => `Unable to remove ${name} from the group appointment.${reason ? ` ${reason}` : ""}`;
export const BulkSignNote = (signedNotesCount: number, signingNotesCount: number) => `${signedNotesCount} OUT OF ${signingNotesCount} NOTES SIGNED`;
export const BulkSignAlert = "Please wait while the signing of notes is completed.";
export const CreatingNote = "One moment, we are creating your note writing experience...";
export const ConfirmDeleteNote = "Are you sure you want to delete this note?";
export const DeleteExistingNote = (labels: Labeling) => `This note is also in ${labels.Product}.  Are you sure you want to delete this note?`;
export const DeleteGridItem = "Are you sure you want to delete this item?";
export const ExistingNoteIsDeleted = (labels: Labeling) => `This note was deleted in ${labels.Product}, therefore it's been deleted in Bells.`;
export const ConfirmRestoreNote = "Are you sure you want to restore this note?";
export const ConfirmDeleteSession = (labels: Labeling) => `Are you sure you want to delete this ${labels.session}?`;
export const ConfirmDeleteGoalTracking = "Are you sure you want to delete this goal tracking?";
export const ConfirmDeleteImage = "Are you sure you want to permanently delete this image?";
export const ConfirmReviewedBackedUpNote = "Please confirm you reviewed the backed up note. It will be deleted on note sign.";
export const ConfirmReviewBackupOnLeave = "There are unsaved changes and unresolved sections from your note back-up. Do you wish to discard them?";
export const ConfirmSessionEdit = (label: string) => `Due to the updated Session Date/Time, the previously selected ${label} are no longer effective. Clicking Continue will take you to the new note without the ${label}. Clicking Cancel will undo the note selection to allow you to make additional updates to the Session Information.`;
export const ConfirmVisitStatusChange = (name: string) => `Just so you're aware, changing the show status will clear ${name}'s note. Are you sure you want to change the status?`;
export const CongratsEmptyNoteGrid = "Yay! You have no notes left to complete!";
export const CustomNoteSectionDelete = "Delete Section";
export const CustomNoteSectionDeleteMessage = "Are you sure you want to delete this Section?";
export const CustomNoteSectionDeleteMessageLinkedToNoteType = "This custom note section is currently used in a note type. Remove the section from the note types to allow it to be deleted.";
export const CustomNoteSectionDeleteMessageLinkedToCns = "This custom note section is linked for use in another section. Update the custom note section that is linked to allow this section to be deleted.";
export const CustomNoteSectionInUseDeleteWarning = "Remove section from note types to allow the delete of the section.";
export const SubformInUseDeleteWarning = "Remove section from parent custom note sections to allow the delete of the subform.";
export const CustomNoteSectionServiceBasedLock = "Service-based sections cannot be deleted, cloned, or converted.";
export const CustomNoteSectionInfo = "Allows entry of a custom note section field in the note. More than one custom note sections may be added to the note type.";
export const CustomNoteSectionNameConflict = "Oops, it looks like a custom note section with this name already exists, please update to have a unique custom note section name.";
export const CustomNoteSectionQuestionDeleteMessage = "Are you sure you want to delete this question?";
export const CnsNarrativeQuestionDeleteMessage = "Removing this question type will clear the Override Narrative Destination on associated note types. Are you sure?";
export const DisabledSignReviewNoteMessage = "No notes have been marked as reviewed. Please review the notes to sign.";
export const EmptyNoteGrid = "No notes have been created yet. You can add one by clicking on the Start Note button.";
export const EmptySyncIssuesGrid = "There are no sync issues to show.";
export const EmptyPacketGrid = "No packets have been created yet. You can add one by clicking on the Start Packet button.";
export const EmptySignedNoteTodayGrid = "No notes have been signed yet today.";
export const EmptySignedNoteGrid = "No notes have been signed yet.";
export const ForceServiceOverride = (labels: Labeling, status: string) => `The group summary must override the ${status} ${labels.serviceType} on this note so that it differs from the group's Show ${labels.serviceType}.`;
export const FutureSessionEndTime = "It looks like this note's session end time is in the future.";
export const GreatJob = "Great job!";
export const HoldNotesNoteInfo = "Note syncs are currently on hold and will continue once the hold period has elapsed";
export const InvalidSignatureTime = "It looks like this note's session end time is in the future. Please correct and sign the note.";
export const MissingLocationRole = "The note is missing the location POS code.  Please edit the session information to fix this issue.";
export const UnsupportedExisitngServiceType = (labels: Labeling) => `The ${labels.location} selected is not configured for the original ${labels.serviceType} for the group. Please select a new ${labels.serviceType}.`;
export const LeftOutSectionsGuidance = "These are the sections we could not auto-copy into your new note type.";
export const NoCareTeam = "No care team documented";
export const NoContactInfo = "No contact information documented";
export const NoData = "No data has been entered in the section.";
export const NoProblems = (labels: Labeling) => `No ${labels.problems} documented`;
export const NoQuestionAdded = "No questions added";
export const NoRecentScribbles = "You do not have any recent scribbles.";
export const NoNoteCategory = (labels: Labeling) => `No ${labels.NoteCategory} documented`;
// TODO: Possibly change this to "Patient not currently enrolled in any programs"
export const NoGoals = "No Active Goals. Please update the treatment plan.";
export const NoEnrollments = (labels: Labeling) => `No ${labels.enrollments} documented`;
export const NoPrograms = "No Program documented";
export const EnrollmentNeeded = (name: string, labels: Labeling) => `${name} has no active ${labels.enrollments}, which are required to start a note. To fix this issue, a new ${labels.enrollment} needs to be created in the source system.`; // TODO: Need to discuss, would it be ok to modify the message so as to avoid a/an determination?
export const NoInsurance = "No insurance documented";
export const NoteCharacterOverlimit = (length: number, product: string) => `Eek! Looks like your note content is ${length} characters, but only 32,700 characters are allowed for ${product}. Please shorten your content.`;
export const NoteSignedSuccessfully = "Note signed successfully!";
export const NoContentPresent = "Nothing to review";
export const NoContentPresentDescription = "Write something to see Bells' feedback.";
export const NoIssuesFound = "Yay! No issues";
export const NoIssuesFoundDescription = "Bells found no issues.";
export const ResetSecondaryTherapistAssignee = (labels: Labeling) => `Whoops! It looks like you have ${labels.patients} who are not assigned to a practitioner. Do you want me to assign them to the primary practitioner?`;
export const PlaygroundPlaceholder = "Answer questions to see how they will show in the note output.";
export const PreparingGroupNote = "Preparing your group note...";
export const PreparingGoalTrackingNote = "Preparing your goal tracking note...";
export const ReviewingNotes = "Please wait while we review your notes...";
export const AssessmentNoteTypeIsEpisodeBased = (labels: Labeling) => `The assessment note type's ${labels.NoteForm} is episode based.`;
export const AssessmentNoteTypeIsNotEpisodeBased = (labels: Labeling) => `The assessment note type's ${labels.NoteForm} is not episode based.`;
export const SelectedEnrollmentEmptyDiagnosis = (labels: Labeling) => `The selected ${labels.enrollment} does not have an active diagnosis associated.`;
export const SelectedEnrollmentEmptyGoalsObjectives = (labels: Labeling) => `The selected ${labels.enrollment} does not have active goals associated.`;
export const SelectedServiceTypeInvalidNoteForm = (labels: Labeling) => `This note type's ${labels.NoteForm} is not compatible with the selected ${labels.serviceType}.`;
export const ScribbleDeleteMessage = "Are you sure you want to delete this scribble?";
export const ScribbleLeaveMessage = "Are you sure you want to leave this scribble?";
export const ScribbleExcessImages = "You can only include up to 10 images per scribble";
export const ScribbleRemovePatient = (patient: string) => `Are you sure you want to remove ${patient} from this scribble?`;
export const SelectNote = "Select a note";
export const SessionDurationDoingGreat = (labels: Labeling) => `Doing great! You're on track to match your average ${labels.session} to sign time.`;
export const SessionDurationDoingGood = (labels: Labeling) => `Doing good! You're close to your average ${labels.session} to sign time.`;
export const SessionDurationMoreThanAverage = (labels: Labeling) => `Current ${labels.session} to sign time is more than your average. Consider using more speed tools.`;
export const NoteTemplateNameConflict = "Oops, it looks like an active Note Template with this name already exists, please update to have a unique Note Template name.";
export const NoteTypeNotConfigured = "No note type configured!";
export const NoteTypeNotAssociated = "No note type associated!";
export const IncompleteSessionInfo = (labels: Labeling) => `Complete the ${labels.session} information to start your note.`;
export const ServiceTypeNotConfigured = (labels: Labeling) => `${labels.ServiceType} not configured`;
export const ServiceTypeGuidance = "Associate services to your defined note types.";
export const ServiceTypeFilterHover = (labels: Labeling) => `Only show ${labels.serviceTypes} without an associated note type.`;
export const TemplateNonEditableSection = "This section cannot be edited as part of a Template.";
export const DeleteSignedNoteAlertHeader = "This is a signed note";
export const NonPreviewableSection = "This section is disabled while previewing.";
export const MissingSignature = "Missing Signature";
export const AuditTabServiceTypeConfiguredToolTip = (labels: Labeling) => `This ${labels.serviceType} is already configured in the Prevent ${labels.Session} Time Overlap validation and cannot be added in this configuration.`;
export const PreventSessionTimeOverlapToolTip = (labels: Labeling) => `This ${labels.serviceType} is already configured in the audit tab configuration and cannot be added in this validation.`;

export const ScribbleDeleteWarning = (attachmentCount: number) => (
  <p><b>Note:</b> {attachmentCount} attachment{attachmentCount !== 1 ? "s" : ""} will also be deleted.</p>
);
export const SwitchPatientAndNote = (labels: Labeling) => `Do you want to switch ${labels.patients} and notes?`;
export const SwitchNote = "Do you want to switch notes?";

export const ServiceTypeBillableTooltip = (serviceTypeIsBillable: boolean, labels: Labeling) => `This ${labels.serviceType} is ${serviceTypeIsBillable ? "" : "not "}billable, but the note type is ${serviceTypeIsBillable ? "not " : ""}billable.`;
export const ServiceTypeRequireUnitsSessionTimeTooltip = (labels: Labeling) => `This ${labels.serviceType} is billable, but the note type does not include a session time section.`;

export const SessionInfoFieldWarning = (labels: Labeling) => `Complete ${labels.session} information to show available note types.`;
export const NoServiceTypesWarningForAdmin = (location: string, labels: Labeling) => `Uh oh! The ${location} does not have a ${labels.serviceType} configured. Please configure this in CT|One.`;
export const NoServiceTypesWarningForNonAdmin = (location: string, labels: Labeling) => `Uh oh! The ${location} does not have a ${labels.serviceType} configured. Please alert your Bells administrator.`;
export const NoNoteTypeWarningForAdmin = (labels: Labeling, serviceType: string) => `Oops! There is no note type associated with the ${labels.serviceType} ${serviceType}, would you like to link one ?`;
export const NoNoteTypeWarningForNonAdmin = (location: string, serviceType: string) => `Uh oh! The combination of ${location} and ${serviceType} does not have a note type configured. Please alert your Bells administrator.`;

export const NoPatientMentionHeader = (labels: Labeling) => `No ${labels.Patient}`;
export const NoPatientMention = (labels: Labeling) => `Associate a ${labels.patient} with this scribble to use mentions.`;
export const NoMentionResultTitle = "No Care Team";
export const NoMentionResultMessage = (labels: Labeling) => <>{labels.Patient} doesn&apos;t have a care team configured.</>;

export const UnsignAlertMessage = "This will remove the signature from the note.  Are you sure you want to continue?";

export const NoDiagnosisMessage = "No Diagnosis";
export const NoSessionsMessage = "There are no active topics for the group.";

export const NoAddOnServicesMessage = "No Add-on Service";

export const TryChangingVisitDate = (labels: Labeling) => `No ${labels.enrollments} during this timeframe, please change the ${labels.session} date`;

export const DurationCannotBeZero = "Duration cannot be zero.";
export const StopTimeShouldBeGreater = "Stop time should be greater than start time.";
export const SessionOverlaps = "This session is overlapping with another session.";
export const DoNotFilterDxByProgram = "Do not filter diagnoses by their program";
export const SelectNoteTypeMessage = "Select a note type.";
export const NoEpisodeSelectedDiagnoses = (labels: Labeling) => `Select a ${labels.enrollment} to select diagnoses.`;
export const NoActiveDiagnosis = (labels: Labeling) => `No Active Diagnosis. Please add a diagnosis for the ${labels.patient} in ${labels.Product}.`;
export const AppointmentManagement = "Appointment Management";
export const AllowAppointmenyToBeCancelled = "Allow appointments to be cancelled";
export const AutomaticallyCreateNoteCancelledAppointment = "Automatically create a note for the cancelled appointment";
export const AutomaticallyCreateApptForSession = (labels: Labeling) => `Allow an appointment to be created from a ${labels.session}`;
export const CreateApptForNote = "Create appointment for the note";
export const SingleApptOverlap = <><p>It looks like you have an appointment in your schedule that overlaps with this note.</p><p> Would you like to tie this note to the appointment?</p></>;
export const MultipleApptOverlap = <><p>It looks like you have more than one appointment in your schedule that overlaps with this note.</p><p>Would you like to tie this note to an existing appointment?</p></>;
export const ApptNoLongerExists = "The appointment linked to this note no longer exists.";
export const ApptAlreadyLinkedWarning = (labels: Labeling) => `There is already another note in ${labels.Product} for this appointment.`;
export const ApptWillBeUnlinkedWarning = (labels: Labeling) => `There is already another note in ${labels.Product} for this appointment.  As a result, this note will now be unlinked from the appointment.`;
export const RequirePlaceOfService = "Place of Service is required on notes";
export const HidePlaceOfService = "Hide Place of Service";
export const SendUnsignedNoteReminder = "Send unsigned note reminder";
export const TextGroupNoteTypeDeleteConfirm = "This group note type is linked to group(s). Are you sure you want to delete it?";
export const TextDefaultGroupNoteTypeDeleteConfirm = "This group note type is set as the primary and is linked to group(s). Are you sure you want to delete it?";

// Bulk Notes
export const AllowMarkAllReview = "Allow mark all review";
export const AllowReportsToBeEmailed = "Allow reports to be emailed";
export const BulkSignMarkAllReview = "Bulk Sign Mark All Review";
export const BulkSignNotes = "Bulk Sign Notes";
export const CancelBulkSign = "No notes have been signed, are you sure you want to cancel the review and bulk sign process?";
export const EmailReport = "Email Report";
export const InCompleteNoteError = "This note is incomplete. Please complete the required section to finalize the note";
export const MarkAllNotReviewed = "Mark all as not reviewed";
export const MarkAllReviewed = "Mark all as reviewed";
export const MarkNotReviewed = "Mark not reviewed";
export const MarkReviewed = "Mark reviewed";
export const NoNotesToSign = "No notes to sign";
export const RestrictEmailingReportsToAdmins = "Restrict emailing reports to admins";
export const SignReviewedNotes = "Sign reviewed notes";
// Bulk Sign Progress
export const Dismiss = "Dismiss";
export const ErrorCount = (count: number) => `Error: ${count}`;
export const FinishedSigning = "Finished Signing Notes";
export const NotSigning = "No note sign in progress now.";
export const SignedCount = (count: number) => `Signed: ${count}`;
export const SigningNotes = "Signing Notes!";
export const ViewListLink = "View Full List";

export const AddDiagnosisSection = "This note type is missing the diagnosis section and is set up to be billable. Do you want to add a diagnosis section?";
export const NoteTemplateDeleteWarning = (count: number) => `${count} note template${count > 1 ? "s" : ""} will be deleted. Do you want to continue?`;
export const ServiceTypeRemoveWarning = (labels: Labeling, plural?: boolean) =>
  `${plural ? "These" : "This"} ${plural ? labels.serviceTypes : labels.serviceType} includes signature configuration. Removing ${plural ? "these" : "this"} ${plural ? labels.serviceTypes : labels.serviceType} from the note type will remove the signature for the note type.`;

// Corrections Messages
export const CorrectionsNoIssueMessage = (label: "note" | "template", firstName: string) => [
  `Congratulations! Your ${label} doesn't include any grammar/spelling corrections!`,
  `Awesome job! This ${label} is looking great for grammar and spelling corrections!`,
  `You're a rock star! There are no grammar or spelling issues in your ${label}!`,
  `Super job! I can't find any grammar or spelling issues in your ${label}!`,
  `Way to go! Your ${label} is free and clear of grammar and spelling issues!`,
  `Nice work ${firstName}, I have no grammar or spelling suggestions to offer you!`,
  `Wow, your writing skills make my job easy! No grammar or spelling issues found in your ${label}!`,
  `Wonderful, this ${label} contains no (or we could say 0) grammar or spelling suggestions!`,
  `Excellent job! Your ${label} doesn't include any grammar or spelling suggestions!`,
  "Look at that! I can't suggest any grammar or spelling updates!",
  "Woo Hoo! No grammar or spelling issues!",
  `You are a ${label} ninja! I can't find any grammar or spelling corrections to suggest!`,
  `Keep up the good work! Your ${label} doesn't include any grammar/spelling corrections!`,
  "Looking good! I can't think of any grammar or spelling suggestions to offer you!",
  `I'm impressed! There are no grammar or spelling issues in your ${label}!`,
  `That's quite the ${label}! I don't have any grammar or spelling suggestions to offer you!`,
  `Supercalifragilisticexpialidocious! This ${label} is looking great for grammar and spelling corrections!`,
  `Making me proud! Your ${label} doesn't include any grammar or spelling issues!`,
  `Bazinga! There are no grammar or spelling issues in your ${label}!`,
  `Boom! Your ${label} doesn't include any grammar/spelling corrections!`,
  "Shazam! I can't think of any grammar or spelling suggestions to offer you!",
  `Woot Woot! This ${label} is looking great for grammar and spelling corrections!`,
  "Darn, you stumped me! I can't find any spelling or grammar suggestions for you!",
  "You nailed it! I don't have any grammar of spelling suggestions for you!",
  "Were you the Spelling Bee champ? I can't find any grammar or spelling corrections!",
  `Success! Your ${label} doesn't need any grammar or spelling corrections from me!`,
  `Great ${label}! I've got no grammar or spelling suggestions!`,
  `I'm speechless! No grammar or spelling suggestions found in this ${label}!`,
];

// Agenda
export const NoEvents = "There are no upcoming events";
export const SelectAnEvent = "Select an event to view details";
export const NoAppointments = (name: string) => `Looks like ${name} doesn't have appointments to display.`;
export const UpcomingAppointmentsHelperText = "Upcoming appointments are within the next two weeks.";

// Groups
export const ExcludeGroupNotes = "Excludes Group Notes";
export const GroupSessions = "Group Sessions";
export const GroupSummary = "Group Summary";
export const GroupTopics = "Group Topics";
export const IncompleteGroupInformation = "The group information must be completed before signing the individual note.";
export const NoNoteTypeConfigured = "There are no note types associated with the group. Please alert your Bells administrator.";
export const NoNoteTypeConfiguredWarningForAdmin = "Oops! There is no note type associated with the group, would you like to link one?";
export const NoNoteTypeConfiguredWarningForNonAdmin = "Uh oh! The group does not have a note type configured. Please alert your Bells administrator.";
export const NoSecondaryTherapistAssigned = "No secondary practitioner has been assigned to this group note";
export const NotAvailable = "N/A";
export const NoteTypeArchiveWhenAssignedToGroupWarning = "Oh snap! Unable to archive the note type because it's tied to a group note. Would you like to fix that?";
export const OverrideGroupSessionInfo = "Override group session information";
export const PrimaryAssignment = "P";
export const RemoveGroupAssociatedPatientWarning = (labels: Labeling) => `This ${labels.patient} is part of the group. Are you sure you want to remove them from the group note?`;
export const SecondaryAssignment = "S";
export const MakePrimary = "Make Primary";

// Patient Access
export const ConfidentialPatientAccessHeader = (name: string, permission: string) => `${name} is restricted because you're not part of their ${permission}.`;
export const ContinueNote = (label?: string) => `Continue the ${label ? label.toLowerCase() + " " : ""}note`;
export const GroupPatientRestrictedAccessMessage = (practitioner: string, count: number, labels: Labeling) => `${practitioner} doesn't have access to ${count} ${count > 1 ? labels.patients : labels.patient} for this group note.`;
export const NoNoteAccess = "You do not have access to this note";
export const NoPatientAccess = (labels: Labeling) => `You don't have access to this ${labels.patient}`;
export const NoSessionAccess = (labels: Labeling) => `Looks like you lost access to this ${labels.patient}. If you feel you should have access, speak with your Bells admin or delete this ${labels.session}.`;
export const RemoveRestrictedPatients = (labels: Labeling) => `Remove restricted ${labels.patient} to start the note`;
export const RequestAccess = "Request Access";
export const RestrictedAccess = "Restricted Access";
export const RestrictedAccessMessage = (count: number, label: string, labels: Labeling) => `You don't have access to ${count} ${count > 1 ? labels.patients : labels.patient} for this ${label.toLowerCase()}.`;
export const WrongPatient = "I do not need access";

// Validations
export const RuleNotMet = "Validation rule not met!";
export const ReviewBlockerRule = "Uh oh! One or more validation rules are not met to start this note. Please review below and update your selections.";
export const ReviewWarnRule = "Uh oh! One or more validation rules are not met to start this note. Please review below before continuing.";
export const UnmetRulesPrompt = "The note does not meet the validation rules. Do you want to correct anything before signing?";
export const BlockingUnmetRulesPrompt = "The note does not meet the validation rules.  You must update the note to sign.";
export const SessionDurationRule = (duration: number, isMore: boolean) => `Your session duration is ${isMore ? "less" : "more"} than the allowed ${Math.round(duration)} minutes.`;
export const MissingInfoLabel = "When the following is missing";
export const AuthorizationLabel = "Minimum authorizations for the service";
export const ProgramSelectionLabel = "Only applies to the following programs";
export const LocationSelectionLabel = (labels: Labeling) => `Only applies to the following ${labels.locations}`;
export const ServiceDateBefore = "When the service date was before";
export const ServiceDateOnAfter = "When the service date is on or after";
export const ServiceSelectLabel = (labels: Labeling) => `And has one of the following ${labels.serviceTypes}`;
export const VisitStatusLabel = "When the note has one of these session statuses";
export const SessionDurationLabel = "Session duration in minutes";
export const ServiceWillChangeTo = (service: string, labels: Labeling) => `The ${labels.serviceType} of your note will change to ${service}.`;
export const ServiceSelectPromptLabel = (labels: Labeling) => `Select the updated ${labels.serviceType} for your note.`;
export const WarnSignNote = "Warn at sign of note";
export const PreventSignNote = "Prevent sign of note";
export const PreventStartNote = "Prevent start of note";
export const ChangeServiceTypePrompt = "Prompt to change service type";
