import { useEffect, useState } from "react";
import { useProductFlag } from "@remhealth/host";
import { useEhr, useErrorHandler, useLabeling } from "@remhealth/core";
import { Intent, Spinner } from "@remhealth/ui";
import { CompositionStatus, PatientNote } from "@remhealth/apollo";
import { isMastodonResponseError } from "@remhealth/mastodon";
import { Text } from "~/text";
import { AlertDialog } from "./deleteNoteAlert.styles";

export interface DeleteNoteAlertProps {
  isOpen: boolean;
  note: PatientNote;
  onCancel: () => void;
  onConfirm: () => void;
}

export const DeleteNoteAlert = (props: DeleteNoteAlertProps) => {
  const { isOpen, onCancel, onConfirm, note } = props;

  const ehr = useEhr();
  const labels = useLabeling();
  const handleError = useErrorHandler();

  const [isNoteDeleted, setIsNoteDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const adminNoteDelete = useProductFlag("AdminNoteDelete");

  useEffect(() => {
    if (isOpen) {
      fetchNoteState();
    }
  }, [isOpen]);

  const isNoteSigned = note.status === CompositionStatus.Final;
  const content = getContent();

  return (
    <AlertDialog
      canEscapeKeyCancel
      $isNoteDeleted={isNoteDeleted || !isNoteSigned}
      cancelButtonText={getCancelButtonText()}
      confirmButtonText={getConfirmButtonText()}
      header={getHeader()}
      intent={getIntent()}
      isOpen={isOpen}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      {isLoading
        ? <Spinner intent="primary" size={25} />
        : (
          <p>
            {content}
          </p>
        )}
    </AlertDialog>
  );

  function getHeader() {
    return isLoading
      ? ""
      : adminNoteDelete && !isNoteDeleted && isNoteSigned
        ? Text.DeleteSignedNoteAlertHeader
        : "";
  }

  function getContent() {
    return adminNoteDelete
      ? isNoteDeleted
        ? Text.ExistingNoteIsDeleted(labels)
        : isNoteSigned ? Text.DeleteExistingNote(labels) : Text.ConfirmDeleteNote
      : Text.ConfirmDeleteNote;
  }

  function getCancelButtonText() {
    return isLoading
      ? ""
      : (adminNoteDelete && isNoteDeleted) || isLoading
        ? ""
        : Text.No;
  }

  function getConfirmButtonText() {
    return isLoading
      ? ""
      : adminNoteDelete && isNoteDeleted
        ? Text.Okay
        : Text.ConfirmDelete;
  }

  function getIntent() {
    return isLoading
      ? Intent.NONE
      : adminNoteDelete && isNoteDeleted
        ? Intent.PRIMARY
        : Intent.DANGER;
  }

  async function fetchNoteState() {
    setIsLoading(true);
    try {
      const response = await ehr.pull("Note", note.id);
      if (response.outcome === "Deleted") {
        setIsNoteDeleted(true);
      } else {
        setIsNoteDeleted(false);
      }
    } catch (error) {
      if (isMastodonResponseError(error) && error.response?.status === 404) {
        setIsNoteDeleted(true);
      } else {
        handleError(error);
      }
    } finally {
      setIsLoading(false);
    }
  }
};
