import { sortBy } from "lodash-es";
import { DateTime } from "luxon";
import { NoteSection, SessionTimeFormat, VisitStatus } from "@remhealth/apollo";
import { Yup } from "@remhealth/core";
import { Text } from "~/text";
import { NoteForm, Session } from "~/notes/types";

export function sessionsSchema(enforceRequired: boolean, allowZeroDurationNonShowNotes: boolean, sessionTimeFormat: SessionTimeFormat, section: NoteSection | undefined): Yup.ArraySchema<Session> {
  return Yup.array<Session>(sessionSchema(enforceRequired, sessionTimeFormat))
    .test("Overlap time", Text.SessionOverlaps, function(value: Session[]) {
      const sessions = value.map((v, index) => ({ ...v, index }));
      const sortedSessions = sortBy(sessions, s => s.start);
      for (let i = 1; i < sortedSessions.length; i++) {
        const previous = sortedSessions[i - 1];
        const current = sortedSessions[i];
        if (current.start
          && current.end
          && previous.end
          && previous.start
          && current.start < previous.end
          && current.start.valueOf() !== current.end.valueOf()
        ) {
          return this.createError({ path: `${this.path}.${current.index}.end`, message: Text.SessionOverlaps });
        }
      }
      return true;
    })
    .test("Require Duration", Text.StopTimeShouldBeGreater, function(value: Session[]) {
      const noteForm = this.parent as NoteForm;
      const canHaveZeroSessionTime = !section?.required || !durationRequired(noteForm);

      const sessions = value ?? [];

      for (let i = 0; i < sessions.length; i++) {
        const session = sessions[i];

        if (!session.start || !session.end || !enforceRequired) {
          continue;
        }

        const start = DateTime.fromJSDate(session.start);
        const end = DateTime.fromJSDate(session.end);

        if (end < start) {
          return this.createError({ path: `${this.path}.${i}.end`, message: Text.StopTimeShouldBeGreater });
        }

        if (!canHaveZeroSessionTime && end.equals(start)) {
          if (sessionTimeFormat === SessionTimeFormat.TimeIncrements) {
            return this.createError({ path: `${this.path}.${i}.end`, message: Text.DurationCannotBeZero });
          }
          return this.createError({ path: `${this.path}.${i}.end`, message: Text.DurationCannotBeZero });
        }
      }

      return true;
    });

  function durationRequired(noteForm: NoteForm) {
    if (noteForm.status === VisitStatus.Show) {
      return true;
    }

    return !allowZeroDurationNonShowNotes;
  }
}

function sessionSchema(enforceRequired: boolean, sessionTimeFormat: SessionTimeFormat): Yup.ObjectSchema<Session> {
  return Yup.object<Session>({
    start: Yup.date().label("Start").required()
      .test("Start time", "Start time should be greater than start time.", function(value: Date | undefined) {
        if (value === undefined) {
          return this.createError({ message: `${Text.Start} time is required.` });
        }
        return true;
      }),
    // TODO add a moreThan and lessThan for date api
    end: Yup.date().label("Stop").requiredWhen(enforceRequired)
      .test("Stop time", "Stop time should be greater than start time.", function(value: Date | undefined) {
        if (value === undefined && enforceRequired) {
          return this.createError({ path: this.path, message: `${Text.Stop} time is required.` });
        }
        return true;
      }).test("Duration", "Duration should be less than 24 hours.", function(value: Date | undefined) {
        if (sessionTimeFormat === SessionTimeFormat.TimeIncrements) {
          const { start } = this.parent as Session;
          if (value && start && DateTime.fromJSDate(value).diff(DateTime.fromJSDate(start)).as("minute") >= 60 * 24) {
            return this.createError({ path: this.path, message: `${Text.Duration} should be less than 24 hours.` });
          }
        }
        return true;
      }),
  });
}
