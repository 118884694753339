import { ulid } from "ulid";
import {
  GenericReference,
  New,
  Patient,
  PersonalPreferences,
  Practice,
  PracticeClients,
  PracticePreferences,
  Practitioner,
  RelatedPerson,
  defaults,
  isApolloResponseError,
  safeStringify
} from "@remhealth/apollo";
import { UserProfile } from "~/auth";

export type PersonalPreferencesData = Omit<New<PersonalPreferences>, "person">;
export type PracticePreferencesData = Omit<New<PracticePreferences>, "practice">;
export type PersonalPreferencesUpdates = Partial<PersonalPreferencesData> | {} | null | undefined;
export type PracticePreferencesUpdates = Partial<PracticePreferencesData> | {} | null | undefined;

export type PreferenceEventHandler<T> = (data: T) => void;
type Unsubscriber = () => void;

type Mutable<T> = { -readonly [K in keyof T]: Mutable<T[K]>; };
export const defaultPracticePreferences = JSON.parse(safeStringify(defaults.practicePreferences)) as Mutable<typeof defaults.practicePreferences>;

export class PreferencesStore {
  private readonly clients: PracticeClients;
  private readonly practiceData: Practice;
  private readonly user: UserProfile | undefined;
  private readonly personalUpdateHandlers = new Map<number, PreferenceEventHandler<PersonalPreferences>>();
  private readonly practiceUpdateHandlers = new Map<number, PreferenceEventHandler<PracticePreferences>>();
  private subscriptionIndex = 0;
  private personal: PersonalPreferences | null | undefined = null;
  private practice: PracticePreferences | null | undefined = null;

  constructor(clients: PracticeClients, practiceData: Practice, user?: UserProfile) {
    this.clients = clients;
    this.practiceData = practiceData;
    this.user = user;
  }

  public get isPracticeLoaded() {
    return this.practice !== null;
  }

  public get loadedPractice() {
    return this.practice;
  }

  public get isPersonalLoaded() {
    return this.personal !== null;
  }

  public get loadedPersonal() {
    return this.personal;
  }

  public registerPersonal(handler: PreferenceEventHandler<PersonalPreferences>): Unsubscriber {
    const index = this.subscriptionIndex++;
    this.personalUpdateHandlers.set(index, handler);
    return () => this.personalUpdateHandlers.delete(index);
  }

  public registerPractice(handler: PreferenceEventHandler<PracticePreferences>): Unsubscriber {
    const index = this.subscriptionIndex++;
    this.practiceUpdateHandlers.set(index, handler);
    return () => this.practiceUpdateHandlers.delete(index);
  }

  public async getPersonal(abort?: AbortSignal): Promise<PersonalPreferences | undefined> {
    if (!this.user) {
      return undefined;
    }

    // Null means we haven't yet queried
    if (this.personal === null) {
      this.personal = await this.fetchPersonal(abort);
    }

    return this.personal;
  }

  public async getPractice(abort?: AbortSignal): Promise<PracticePreferencesData> {
    // Null means we haven't yet queried
    if (this.practice === null) {
      this.practice = await this.fetchPractice(abort);
    }

    return this.practice ?? defaultPracticePreferences;
  }

  public async updatePersonal(updates: PersonalPreferencesUpdates, abort?: AbortSignal): Promise<PersonalPreferences> {
    if (this.personal === null) {
      this.personal = await this.fetchPersonal(abort);
    }

    const savedPreferences = await this.upsertPersonal(this.personal, updates, abort);

    this.personal = savedPreferences;

    this.personalUpdateHandlers.forEach(handler => handler(savedPreferences));

    return savedPreferences;
  }

  public async updatePractice(updates: PracticePreferencesUpdates, abort?: AbortSignal): Promise<PracticePreferences> {
    if (this.practice === null) {
      this.practice = await this.fetchPractice(abort);
    }

    const savedPreferences = await this.upsertPractice(this.practice, updates, abort);

    this.practice = savedPreferences;

    this.practiceUpdateHandlers.forEach(handler => handler(savedPreferences));

    return savedPreferences;
  }

  private async fetchPersonal(abort?: AbortSignal): Promise<PersonalPreferences | undefined> {
    if (!this.user) {
      return undefined;
    }

    const personalPreferences = await this.clients.personalPreferences.query({
      filters: [{ person: { in: [this.user.id] } }],
      abort,
    });

    return personalPreferences.results.at(0);
  }

  private async fetchPractice(abort?: AbortSignal): Promise<PracticePreferences | undefined> {
    const practicePreferences = await this.clients.practicePreferences.query({
      filters: [{ practice: { in: [this.practiceData.id] } }],
      abort,
    });

    return practicePreferences.results.at(0);
  }

  private async upsertPersonal(existingPreferences: PersonalPreferences | undefined, updates: PersonalPreferencesUpdates, abort?: AbortSignal): Promise<PersonalPreferences> {
    if (!this.user) {
      throw new Error("Missing user profile data while saving personal preferences.");
    }

    const personalPreferences: New<PersonalPreferences> = {
      ...existingPreferences ?? createNewPersonalPreferences(this.user),
      ...updates,
    };

    if (!personalPreferences.id) {
      personalPreferences.id = ulid();
    }

    try {
      return await this.clients.personalPreferences.update(personalPreferences);
    } catch (error) {
      if (isApolloResponseError(error) && error.response.status === 409) {
        if (existingPreferences) {
          this.personal = await this.fetchPersonal(abort);
          return this.personal ?? existingPreferences;
        }
      }
      throw error;
    }
  }

  private async upsertPractice(existingPreferences: PracticePreferences | undefined, updates: PracticePreferencesUpdates, abort?: AbortSignal): Promise<PracticePreferences> {
    const practicePreferences: New<PracticePreferences> = {
      resourceType: "PracticePreferences",
      practice: {
        id: this.practiceData.id,
        display: this.practiceData.display,
      },
      ...defaultPracticePreferences,
      ...existingPreferences,
      ...updates,
    };

    if (!practicePreferences.id) {
      practicePreferences.id = ulid();
    }

    try {
      return await this.clients.practicePreferences.update(practicePreferences);
    } catch (error) {
      if (isApolloResponseError(error) && error.response.status === 409) {
        if (existingPreferences) {
          this.practice = await this.fetchPractice(abort);
          return this.practice ?? existingPreferences;
        }
      }
      throw error;
    }
  }
}

export function createNewPersonalPreferences(person: GenericReference<Practitioner | RelatedPerson | Patient>): New<PersonalPreferences> {
  return {
    id: ulid(),
    resourceType: "PersonalPreferences",
    person,
    bellsWebFontAppearance: "Normal",
    bellsWebTheme: "Light",
    doNotSurvey: false,
    militaryTime: false,
    notificationOptOut: false,
    pins: [],
    scheduler: {},
    textExpansions: true,
    mobileTextExpansions: false,
  };
}
