import {
  Approximate,
  CarePlanActivity,
  Condition,
  DiagnosisRole,
  Duration,
  Encounter,
  ItineraryTopic,
  Note,
  NoteSection,
  NoteSectionFormat,
  Organization,
  Participant,
  Patient,
  PatientNote,
  type Practice,
  Practitioner,
  ProblemActivity,
  Reference,
  RelatedPerson,
  SessionTimeFormat,
  VisitStatus
} from "@remhealth/apollo";
import { DateFormats, DurationFormats } from "@remhealth/ui";
import { PracticePreferencesData, getProductFlag } from "@remhealth/host";
import { PlaceholderContext } from "@remhealth/core";
import { Text } from "~/text";
import { getConceptText } from "~/codeable";
import { QuestionnaireContext } from "~/questionnaire/contexts";
import { renderQuestionnaire } from "~/questionnaire/narrative/utils";
import { getVisitStatus } from "~/utils";
import { spansMidnight } from "../utils";
import { GoalsObjectivesSection } from "./goalObjectivesSection";

export interface NoteSummarySectionProps {
  note: PatientNote;
  practice: Practice;
  section: NoteSection;
  practicePreferences: PracticePreferencesData;
  encounter: Encounter;
  conditions: Condition[];
  problems: Condition[];
  insurances: Reference<Organization>[];
  patient: Patient;
  owner: Practitioner | undefined;
  guardian: RelatedPerson | undefined;
  isPatientView?: boolean;
}

export function noteSummarySection(props: NoteSummarySectionProps): JSX.Element | null {
  const {
    note,
    section,
    encounter,
    conditions,
    problems,
    practice,
    practicePreferences,
  } = props;

  const numberedGoals = getProductFlag(practice, "NumberedGoals");
  const isShowStatus = getVisitStatus(encounter.status) === VisitStatus.Show;
  const showSessionTime = !note.partOf || (isShowStatus && note.overrideSessionInformation);
  const militaryTime = practicePreferences.militaryTimeInPrintouts ?? false;

  switch (section.format) {
    case NoteSectionFormat.Diagnosis:
      return renderDiagnoses();
    case NoteSectionFormat.GoalsObjectives:
      return renderGoalsObjectives();
    case NoteSectionFormat.SessionTime:
      return showSessionTime
        ? renderSessionTime(note, encounter, militaryTime, practice)
        : null;
    case NoteSectionFormat.Text:
      return renderNoteTextSection(section);
    case NoteSectionFormat.CtoneCustomDropdown:
      return renderCtoneCustomDropdown();
    case NoteSectionFormat.Form:
      return renderNoteSectionForm();
    case NoteSectionFormat.ClinicalQualityIndicator:
      return renderClinicalQualityCodes();
    case NoteSectionFormat.EvidenceBasedPractices:
      return renderEvidenceBasedPracticeCodings();
    case NoteSectionFormat.Problems:
      return renderProblems();
    case NoteSectionFormat.GoalTracking:
      return !note.derivedFrom?.resource?.partOf ? renderCarePlanActivities(note.derivedFrom?.resource?.carePlanActivities ?? [], false) : null;
    case NoteSectionFormat.AddOn:
      return renderAddOnServices();
    default:
      return null;
  }

  function renderNoteSectionForm() {
    const output = renderFormSection(props);

    if (!output) {
      return null;
    }

    return (
      <article dangerouslySetInnerHTML={{ __html: output }} />
    );
  }

  function renderGoalsObjectives() {
    if (encounter.carePlanActivities.length === 0) {
      return null;
    }

    return (
      <GoalsObjectivesSection
        carePlanActivities={encounter.carePlanActivities}
        ordered={numberedGoals}
      />
    );
  }

  function renderDiagnoses() {
    const { diagnoses } = encounter;

    if (diagnoses.length === 0) {
      return null;
    }

    return (
      <ol>
        {diagnoses.map(d => {
          const condition = conditions.find(c => c.id === d.condition.id);
          const code = condition ? getConceptText(condition.code, "diagnosis", true) : "";
          const isPrimary = d.rank === 1 || d.role === DiagnosisRole.Billing || condition?.priority === 1;
          return (
            <li key={d.condition.id}>
              <span className="billing label">{isPrimary ? "P" : ""}</span>{" "}
              <span className="code">{code}</span>{" "}
              <span className="display">{condition?.display}</span>{" "}
              <span className="onset">{DateFormats.date(Approximate.toDate(condition?.period?.start))}</span>
            </li>
          );
        })}
      </ol>
    );
  }

  function renderProblems() {
    const { problems: problemList } = encounter;

    if (problemList.length === 0) {
      return null;
    }

    return (
      <ol>
        {problemList.map(renderProblemItem)}
      </ol>
    );

    function renderProblemItem(problemActivity: ProblemActivity, index: number) {
      const problem = problems.find(p => p.id === problemActivity.problem.id);
      const date = Approximate.toDate(problem?.period?.start);
      return (
        <li key={index}>
          {date && `${DateFormats.date(date)} - `}{problemActivity.problem.display}
          {(problemActivity.comments?.value || problemActivity.comments?.plainText) && (
            <dl className="comments">
              <dt className="label">{Text.Comment}</dt>
              {problemActivity.comments?.value?.trim()
                ? <dd dangerouslySetInnerHTML={{ __html: problemActivity.comments.value ?? "" }} className="content" />
                : <dd className="content">{problemActivity.comments?.plainText}</dd>}
            </dl>
          )}
        </li>
      );
    }
  }

  function renderAddOnServices() {
    const { additionalServices = [] } = note;

    if (additionalServices.length === 0) {
      return null;
    }

    return (
      <ol>
        {additionalServices.map((a, index) => {
          return (
            <li key={index}>
              <span className="service">{a.coding.display}</span>
              {a.duration && Duration.humanize(a.duration)}
              {(a.comment?.value || a.comment?.plainText) && (
                <dl className="comments">
                  <dt className="label">{Text.Comment}</dt>
                  {a.comment?.value?.trim()
                    ? <dd dangerouslySetInnerHTML={{ __html: a.comment.value ?? "" }} className="content" />
                    : <dd className="content">{a.comment?.plainText}</dd>}
                </dl>
              )}
            </li>
          );
        })}
      </ol>
    );
  }

  function renderCtoneCustomDropdown() {
    if (!note.customOption) {
      return null;
    }

    return <p>{note.customOption?.description}</p>;
  }

  function renderClinicalQualityCodes() {
    if (note.clinicalQualityCodes.length === 0) {
      return null;
    }

    return (
      <ul>
        {note.clinicalQualityCodes.map(cqc => {
          const { code, description } = cqc;
          return (
            <li key={code}>
              <span className="code">{code}</span>{" "}
              <span className="display">{description}</span>{" "}
            </li>
          );
        })}
      </ul>
    );
  }

  function renderEvidenceBasedPracticeCodings() {
    if (note.evidenceBasedPracticesCodings.length === 0) {
      return null;
    }

    if (section.useEvidenceBasedPracticeElements) {
      const { code, display, elements } = note.evidenceBasedPracticesCodings[0];
      return (
        <p className="ebp-service-elements">
          <span className="code">{code}</span>
          {display && <span className="display">{` ${display}`}</span>}
          <ul>
            {elements.map(coding => {
              const { code, display } = coding;
              return (
                <li key={coding.code}>
                  <span className="code">{code}</span>
                  {display && <span className="display">{` ${display}`}</span>}
                </li>
              );
            })}
          </ul>

        </p>
      );
    }

    return (
      <ul>
        {note.evidenceBasedPracticesCodings.map(coding => {
          const { code, display } = coding;
          return (
            <li key={code}>
              <span className="code">{code}</span>
              {display && <span className="display">{` ${display}`}</span>}
            </li>
          );
        })}
      </ul>
    );
  }

  function renderNoteTextSection(section: NoteSection) {
    const noteSection = note.sections.find(n => n.format === section.format && n.name === section.name);
    return renderTextSection(noteSection);
  }
}

export function renderFormSection(props: NoteSummarySectionProps) {
  const {
    note,
    section,
    encounter,
    practice,
    practicePreferences,
    insurances,
    patient,
    guardian,
    owner,
    isPatientView = false,
  } = props;

  if (section.formAnswers.length === 0) {
    return null;
  }

  if (!section.form?.resource) {
    throw new Error(`Note Section ${section.name} missing form resource`);
  }

  const renderInline = getProductFlag(practice, "RenderImportCNSInline");
  const militaryTime = practicePreferences.militaryTimeInPrintouts ?? false;

  const questionnaireContext: QuestionnaireContext = {
    patient,
    visitDate: encounter.period.start,
    program: encounter.program,
    location: encounter.location?.location,
    locationRole: encounter.location?.role,
    serviceLocation: encounter.location?.kind,
    serviceType: encounter.serviceType,
    insurances,
    narrativeOverrideLinkId: note.definition?.resource?.narrativeDestination,
    period: encounter.period,
  };

  const placeholderContext: PlaceholderContext = {
    patient,
    guardian,
    practitioner: owner,
    practicePreferences,
    location: encounter.location?.location,
    placeOfService: encounter.location?.role ?? encounter.location?.kind,
  };

  return renderQuestionnaire(section.form.resource.elements, section.formAnswers, {
    questionnaireContext,
    placeholderContext,
    militaryTime,
    isPatientView,
    renderInline: renderInline && section.form?.resource?.mode === "Import",
  });
}

export function renderTextSection(noteSection?: NoteSection) {
  if (!noteSection) {
    return null;
  }

  const { text } = noteSection;
  if (!text?.plainText?.length || !text.value) {
    return null;
  }

  return (
    <article dangerouslySetInnerHTML={{ __html: text.value }} />
  );
}

export function renderSessionTime(note: Note, encounter: Encounter, militaryTime: boolean, practice: Practice) {
  const isMyAvatar = practice.product === "myAvatar";
  const startLabel = isMyAvatar ? Text.ServiceStart : Text.Start;
  const stopLabel = isMyAvatar ? Text.ServiceStop : Text.Stop;
  const durationLabel = isMyAvatar ? Text.ServiceDuration : Text.Duration;
  const units = encounter.serviceQuantity;

  if (note.sessionTimeFormat === SessionTimeFormat.Duration || note.sessionTimeFormat === SessionTimeFormat.DurationMinutes) {
    return (
      <dl>
        <dt className="label">{startLabel}</dt>
        <dd>{DateFormats.time(Approximate.toDate(encounter.period.start), militaryTime)}</dd>
        {isMyAvatar && (
          <>
            <dt className="label">{stopLabel}</dt>
            <dd>{DateFormats.time(Approximate.toDate(encounter.period.end), militaryTime)}</dd>
            <dt className="label">{durationLabel}</dt>
            <dd>{encounter.duration ? DurationFormats.time(Duration.toLuxon(encounter.duration)) : ""}</dd>
          </>
        )}
        {!isMyAvatar && (
          <>
            <dt className="label">{Text.LengthOfSession}</dt>
            <dd>{encounter.duration ? DurationFormats.time(Duration.toLuxon(encounter.duration)) : ""}</dd>
          </>
        )}
        {units && (
          <>
            <dt className="label">{Text.Units}</dt>
            <dd>{units}</dd>
          </>
        )}
      </dl>
    );
  }

  const participants = note.participants.filter(p => p.role === "PrimaryPerformer");
  const isEmpty = !participants.some(p => p.period?.start && p.period?.end);
  if (isEmpty) {
    return null;
  }

  const showDay = showDate(participants);
  return (
    <>
      <ul>
        {participants.map((p, index) => {
          const start = showDay
            ? DateFormats.dateTime(Approximate.toDate(p.period?.start), militaryTime)
            : DateFormats.time(Approximate.toDate(p.period?.start), militaryTime);
          const stop = showDay
            ? DateFormats.dateTime(Approximate.toDate(p.period?.end), militaryTime)
            : DateFormats.time(Approximate.toDate(p.period?.end), militaryTime);
          const minutes = p.period?.start && p.period?.end
            ? Math.round(Approximate.toDateTime(p.period?.end)?.diff(Approximate.toDateTime(p.period?.start)).as("minute"))
            : 0;
          const duration = DurationFormats.time(Duration.toLuxon(Duration.fromMinutes(minutes)));
          return (
            <li key={index}>
              <span className="start"><span className="label">{startLabel}</span>{" "} <span className="value">{start}</span></span>{"  "}
              <span className="stop"><span className="label">{stopLabel}</span>{" "} <span className="value">{stop}</span></span>{"  "}
              <span className="duration"><span className="label">{durationLabel}</span>{" "} <span className="value">{duration}</span></span>{"  "}
            </li>
          );
        })}
      </ul>
      {!isMyAvatar && (
        <p className="session-length">
          <span className="label">{Text.LengthOfSession}</span>{" "}
          <span className="value">{encounter.duration ? DurationFormats.time(Duration.toLuxon(encounter.duration)) : ""}</span>
        </p>
      )}
      {units && (
        <p className="session-units">
          <span className="label">{Text.Units}</span>{" "}
          <span className="value">{units}</span>
        </p>
      )}
    </>
  );
}

function showDate(participants: Participant[]) {
  return participants.some(spansMidnight);
}

export function renderGroupSessions(topics: ItineraryTopic[]) {
  if (topics.length === 0) {
    return null;
  }

  return (
    <ul>
      {topics.map(topic => {
        return (
          <li key={topic.name}>
            <dl>
              <dt className="label">{topic.name}</dt>
              <dd className="content">{topic.description}</dd>
            </dl>
          </li>
        );
      })}
    </ul>
  );
}

export function renderCarePlanActivities(carePlanActivities: CarePlanActivity[], showHeader = true) {
  if (carePlanActivities.length === 0) {
    return null;
  }

  return (
    <section className="goal-section">
      {showHeader && <h3>Goal Tracking Information</h3>}
      <GoalsObjectivesSection
        showGoalTrackingInfo
        carePlanActivities={carePlanActivities}
      />
    </section>
  );
}
