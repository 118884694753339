import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Encounter, GroupNote, IStores, ItineraryTopic, NoteSection, NoteSectionFormat, PatientNote, type Practice, VisitStatus } from "@remhealth/apollo";
import { useAbort } from "@remhealth/ui";
import {
  ApolloClients,
  PracticePreferencesData,
  useApollo,
  usePersonalPreferences,
  usePracticePreferences,
  useUserSession
} from "@remhealth/host";
import { forEachAsync, useLabeling, useStore } from "@remhealth/core";
import { htmlToText } from "@remhealth/compose";
import { Text } from "~/text";
import { getVisitStatus } from "~/utils";
import { hasLinkedSectionForm } from "~/questionnaire/flavors";
import { LoadNoteSummaryOptions, NoteSummary, NoteSummaryProps, loadNoteSummary } from "./noteSummary";
import { NoteSummarySectionProps, noteSummarySection, renderFormSection, renderGroupSessions, renderSessionTime, renderTextSection } from "./noteSummarySection";
import { StyledNoteSummary } from "./noteSummaryPreview.styles";

export interface NoteSummaryPreviewProps {
  note: PatientNote;
  onReady?: () => void;
  className?: string;
  isPrintView?: boolean;
  isPatientView?: boolean;
}

export function NoteSummaryPreview(props: NoteSummaryPreviewProps) {
  const { className, note, isPrintView = false, isPatientView = false, onReady } = props;

  const apollo = useApollo();
  const store = useStore();
  const abort = useAbort();
  const session = useUserSession();
  const practicePreferences = usePracticePreferences();
  const personalPreferences = usePersonalPreferences();
  const labels = useLabeling();

  const [data, setData] = useState<NoteSummaryProps>();

  useEffect(() => {
    loadData();
  }, [note, practicePreferences, personalPreferences]);

  useEffect(() => {
    if (data) {
      onReady?.();
    }
  }, [data]);

  return data ? <StyledNoteSummary className={className} {...data} /> : <></>;

  async function loadData() {
    const options: LoadNoteSummaryOptions = {
      practice: session.practice,
      practicePreferences,
      personalPreferences,
      labels,
      allowImages: (media) => apollo.media.downloadContent(media, "base64"),
      isPatientView,
      isPrintView,
    };

    setData(await loadNoteSummary(store, note, options, abort.signal));
  }
}

export interface NoteSummaryMarkup {
  summary: string;
  sections: NoteSummarySectionMarkup[];
  elements: Map<string, string>;
}

export interface NoteSummarySectionMarkup {
  name: string;
  markup: string | null;
}

export async function generateNoteSummaryMarkup(
  store: IStores<ApolloClients>,
  note: PatientNote,
  options: LoadNoteSummaryOptions,
  abort: AbortSignal
): Promise<NoteSummaryMarkup> {
  const noteSummary = await loadNoteSummary(store, note, options, abort);
  const { topics } = noteSummary.encounter;
  const summaryEl = document.createElement("div");
  const summaryRoot = createRoot(summaryEl);

  const sections = await forEachAsync(10, note.sections, async section => {
    const markup = await generateNoteSectionMarkup({ ...noteSummary, section });
    return { name: section.name, markup };
  });

  const elements = generateElementSummaryMarkup(noteSummary, note.sections);

  if (note.partOf && topics.length > 0) {
    sections.push({
      name: Text.GroupTopics,
      markup: await generateGroupSessionsMarkup({ topics }),
    });
  }

  return new Promise<NoteSummaryMarkup>((resolve, reject) => {
    try {
      summaryRoot.render(<NoteSummary {...noteSummary} onReady={handleReady} />);
    } catch (error) {
      return reject(error);
    }

    function handleReady() {
      resolve({
        summary: summaryEl.innerHTML,
        sections,
        elements,
      });
    }
  });
}

export async function generateGroupNoteSummaryMarkup(
  note: GroupNote,
  encounter: Encounter,
  practice: Practice,
  practicePreferences: PracticePreferencesData
): Promise<NoteSummaryMarkup> {
  const sections = await forEachAsync(10, note.sections, async section => {
    const markup = await generateGroupSectionMarkup({
      note,
      encounter,
      section,
      practice,
      militaryTimeInPrintouts: practicePreferences.militaryTimeInPrintouts,
    });
    return { name: section.name, markup };
  });

  return {
    summary: sections.map(s => s.markup).join(""),
    sections,
    elements: new Map(),
  };
}

async function generateNoteSectionMarkup(sectionProps: NoteSummarySectionProps): Promise<string> {
  const element = document.createElement("div");
  const root = createRoot(element);

  return new Promise<string>((resolve, reject) => {
    try {
      root.render(<NoteSummarySectionComponent {...sectionProps} onReady={handleReady} />);
    } catch (error) {
      return reject(error);
    }

    function handleReady() {
      resolve(element.innerHTML);
    }
  });
}

interface NoteSummarySectionComponentProps extends NoteSummarySectionProps {
  onReady: () => void;
}

function NoteSummarySectionComponent(props: NoteSummarySectionComponentProps) {
  useEffect(props.onReady, []);
  return <>{noteSummarySection(props)}</>;
}

interface GroupSectionMarkupProps {
  note: GroupNote;
  encounter: Encounter;
  section: NoteSection;
  practice: Practice;
  militaryTimeInPrintouts: boolean;
}

async function generateGroupSectionMarkup(props: GroupSectionMarkupProps): Promise<string> {
  const element = document.createElement("div");
  const root = createRoot(element);

  return new Promise<string>((resolve, reject) => {
    try {
      root.render(<GroupSectionComponent {...props} onReady={handleReady} />);
    } catch (error) {
      return reject(error);
    }

    function handleReady() {
      resolve(element.innerHTML);
    }
  });
}

interface GroupSectionComponentProps extends GroupSectionMarkupProps {
  onReady: () => void;
}

function GroupSectionComponent(props: GroupSectionComponentProps) {
  const { note, encounter, practice, section, militaryTimeInPrintouts, onReady } = props;
  useEffect(onReady, []);

  if (section.format === NoteSectionFormat.Text) {
    return <>{renderTextSection(section)}</>;
  }

  const isShowStatus = getVisitStatus(encounter.status) === VisitStatus.Show;

  return isShowStatus
    ? <>{renderSessionTime(note, encounter, militaryTimeInPrintouts, practice)}</>
    : <></>;
}

async function generateGroupSessionsMarkup(props: GroupSessionsMarkupProps): Promise<string> {
  const element = document.createElement("div");
  const root = createRoot(element);

  return new Promise<string>((resolve, reject) => {
    try {
      root.render(<GroupSessionsComponent {...props} onReady={handleReady} />);
    } catch (error) {
      return reject(error);
    }

    function handleReady() {
      resolve(element.innerHTML);
    }
  });
}

interface GroupSessionsMarkupProps {
  topics: ItineraryTopic[];
}

interface GroupSessionsComponentProps extends GroupSessionsMarkupProps {
  onReady: () => void;
}

function GroupSessionsComponent(props: GroupSessionsComponentProps) {
  const { topics, onReady } = props;
  useEffect(onReady, []);
  return <>{renderGroupSessions(topics)}</>;
}

function generateElementSummaryMarkup(noteSummary: NoteSummaryProps, sections: NoteSection[]): Map<string, string> {
  const elements = new Map<string, string>();

  for (const section of sections) {
    if (!section.form?.resource) {
      continue;
    }

    for (const answer of section.formAnswers) {
      const value = answer.values.at(0);
      if (!value) {
        continue;
      }

      const element = section.form.resource.elements.find(e => e.linkId === answer.linkId);

      if (!element || !hasLinkedSectionForm(element)) {
        continue;
      }

      const markup = renderFormSection({ ...noteSummary, section });

      value.valueText = markup ? {
        value: markup,
        plainText: htmlToText(markup),
      } : undefined;
    }
  }

  return elements;
}
