import { PropsWithChildren, createContext, useEffect, useMemo, useState } from "react";
import { createReference, usePracticePreferences } from "@remhealth/host";
import { FormContent, PlaceholderContext, useStore } from "@remhealth/core";
import { EpisodeOfCare, Group, GroupNote, NoteDefinition, ParticipantRole, Patient, PatientNote, Practitioner, Reference, RelatedPerson, safeStringify } from "@remhealth/apollo";
import { OpenPatient } from "~/contexts";
import { fetchGuardian, getVisitStatus, isAllowedVisitStatus } from "~/utils";
import { QuestionnaireContext } from "~/questionnaire/contexts";
import { SpeechContext } from "~/compose/language/speechContext";
import { type GroupNoteForm, NoteForm, isGroupNoteForm } from "./types";

export interface NoteContext extends QuestionnaireContext {
  noteId: string | undefined;
  patient: Patient | undefined;
  definition: Reference<NoteDefinition> | undefined;
  guardian: RelatedPerson | undefined;
  practitioner: Practitioner | undefined;
  group: Reference<Group> | undefined;
  episodeOfCare: Reference<EpisodeOfCare> | undefined;
}

export const NoteContext = createContext<NoteContext>({
  noteId: undefined,
  patient: undefined,
  practitioner: undefined,
  group: undefined,
  episodeOfCare: undefined,
  program: undefined,
  serviceType: undefined,
  definition: undefined,
  location: undefined,
  guardian: undefined,
  insurances: [],
  visitDate: undefined,
  locationRole: undefined,
  serviceLocation: undefined,
  narrativeOverrideLinkId: undefined,
  period: undefined,
});

export interface NoteFormRootProps {
  form: FormContent<NoteForm> | FormContent<GroupNoteForm>;
  patientNote: PatientNote | null;
  groupNote: GroupNote | null;
  patient: OpenPatient | null;
}

export function NoteFormRoot(props: PropsWithChildren<NoteFormRootProps>) {
  const { form, patientNote, groupNote, patient, children } = props;

  const store = useStore();

  const [guardian, setGuardian] = useState<RelatedPerson>();

  useEffect(() => {
    if (patient) {
      loadGuardian(patient.patient, false);
      return patient?.onPullComplete("noteContext", () => handlePatientPullComplete(patient.patient));
    }
    return undefined;
  }, [patient?.patient.id]);

  const hideElementLinkIds = useMemo(getHiddenElementLinkIds, [patientNote, groupNote]);

  const context = getNoteContext(form);
  const noteContext = useMemo(() => context, [guardian, safeStringify(context)]);

  return (
    <NoteContextProvider context={noteContext}>
      {children}
    </NoteContextProvider>
  );

  function getNoteContext(form: FormContent<NoteForm> | FormContent<GroupNoteForm>): NoteContext {
    const noteForm = isGroupNoteForm(form) ? null : form;
    const notePatient = patient?.patient ?? noteForm?.values.patient?.resource;

    return {
      noteId: form.fields.id.value,
      patient: notePatient,
      practitioner: patientNote?.participants.find(p => p.role === ParticipantRole.PrimaryPerformer)?.individual.resource,
      group: groupNote?.subject,
      location: form.fields.location.value ? createReference(form.fields.location.value) : undefined,
      locationRole: form.fields.locationRole.value,
      serviceLocation: form.fields.locationKind.value,
      episodeOfCare: notePatient && noteForm?.fields.episodeOfCareId.value ? { partition: notePatient.id, id: noteForm.fields.episodeOfCareId.value } : undefined,
      program: form.values.program ? createReference(form.values.program) : undefined,
      definition: noteForm ? createReference(noteForm.fields.definition.value) : undefined,
      serviceType: form.fields.serviceType.value ? createReference(form.fields.serviceType.value) : undefined,
      visitDate: form.fields.period.value.start,
      narrativeOverrideLinkId: noteForm?.fields.definition.fields.narrativeDestination?.value,
      hideElementLinkIds,
      guardian,
      insurances: noteForm?.fields.insurances.value ?? [],
      period: form.fields.period.value,
    };
  }

  function getHiddenElementLinkIds() {
    return patientNote ? getHiddenFormElements(patientNote, groupNote) : new Set<string>();
  }

  async function handlePatientPullComplete(patient: Reference<Patient>) {
    loadGuardian(patient, true);
  }

  async function loadGuardian(patient: Reference<Patient>, refresh: boolean) {
    setGuardian(await fetchGuardian(store.relatedPeople, patient, undefined, refresh));
  }
}

export interface NoteContextProviderProps {
  context: NoteContext;
}

export function NoteContextProvider(props: PropsWithChildren<NoteContextProviderProps>) {
  const { context, children } = props;

  const practicePreferences = usePracticePreferences();

  const speechContext = useMemo<SpeechContext>(() => ({
    patient: context.patient,
    definition: context.definition,
    serviceType: context.serviceType,
    sectionName: undefined,
  }), [context]);

  const placeholderContext = useMemo<PlaceholderContext>(() => ({
    patient: context.patient,
    guardian: context.guardian,
    practicePreferences,
    practitioner: context.practitioner,
    location: context.location,
    placeOfService: context.locationRole ?? context.serviceLocation,
  }), [context]);

  return (
    <NoteContext.Provider value={context}>
      <QuestionnaireContext.Provider value={context}>
        <PlaceholderContext.Provider value={placeholderContext}>
          <SpeechContext.Provider value={speechContext}>
            {children}
          </SpeechContext.Provider>
        </PlaceholderContext.Provider>
      </QuestionnaireContext.Provider>
    </NoteContext.Provider>
  );
}

export function getHiddenFormElements(note: PatientNote, groupNote: GroupNote | null): Set<string> {
  const visitStatus = getVisitStatus(note.encounter.resource?.status);

  // Group note sections take precedence over individual patient sections
  const groupNoteForms = groupNote?.sections.flatMap(f => f.form?.resource ?? []) ?? [];
  const noteForms = note.sections.flatMap(f => f.form?.resource ?? []);
  const forms = groupNoteForms.concat(noteForms);

  const seenUniqueIds = new Set<string>();
  const hideLinkIds = new Set<string>();

  for (const form of forms) {
    if (isAllowedVisitStatus(form, visitStatus)) {
      for (const element of form.elements) {
        const uniqueId = element.extensions?.find(e => e.name === "uniqueId")?.value;

        if (uniqueId) {
          if (seenUniqueIds.has(uniqueId)) {
            hideLinkIds.add(element.linkId);
          } else {
            seenUniqueIds.add(uniqueId);
          }
        }
      }
    }
  }

  return hideLinkIds;
}
